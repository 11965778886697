import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { SocietiesService } from 'src/app/services/societies/societies.service';

@Component({
  selector: 'app-add-society-cof',
  templateUrl: './add-society-cof.component.html',
  styleUrls: ['./add-society-cof.component.css']
})

export class AddSocietyCofComponent implements OnInit {


  isSaved: Boolean = true;

  society: any = {
    name: "",
    email: "",
    secondEmail: "",
    webSite: "",
    pays: "",
    numtel: "",
    secondNumtel: "",
    adress: null,
    //logo: ""
  }

  places: any[] = [];
  @ViewChild('autoInput') input: any;
  @ViewChild('autoInput2') input2: any;

  countries: any[] = [
    "Allemagne", "Angleterre", "Autriche", "Belgique",
    "Bulgarie", "Chypre", "Croatie",
    "Danemark", "Espagne", "Estonie",
    "Finlande", "France", "Grèce",
    "Hongrie", "Irlande", "Italie",
    "Lettonie", "Lituanie", "Luxembourg",
    "Malte", "Pays-Bas", "Pologne",
    "Portugal", "République tchèque",
    "Roumanie", "Slovaquie", "Slovénie",
    "Suisse", "Suède", "Tunisie"
  ]

  constructor(private societyService: SocietiesService,
    private clientsService: ClientsService,
  ) {
  }


  async ngOnInit() {

  }

  async onChange(input: string) {
    if (input === "adress")
      this.places = await this._fetchAllPlace(this.input.nativeElement.value)
    else
      this.places = await this._fetchAllPlace(this.input2.nativeElement.value)

    // this.getFilteredOptions(this.input.nativeElement.value);
  }


  async onSelectionChange(event: any, input: string) {
    // console.log(event.option.value)
    const selectedValue = event.option.value;
    this.places = [] //this.getFilteredOptions($event);
    var detailsPlaceFrom = await this._fetchDetailsPlace(selectedValue.place_id)
    // console.log(detailsPlaceFrom)

    const country = this.getCountry(detailsPlaceFrom)
    var adress = {
      lat: detailsPlaceFrom.geometry.location.lat,
      lng: detailsPlaceFrom.geometry.location.lng,
      label_target: selectedValue.description,
      country: country
    }
    // console.log("adresssssssssss :::::::::::: ")
    // console.log(adress)
    // console.log(country)

    if (input === "adress") {
      this.input.nativeElement.value = selectedValue.description
      this.society.adress = adress
      this.society.pays = country


    }

    // console.log(this.provider.adressSociety)
    // this.positionChanged.emit(new PositionModel(
    //   detailsPlaceFrom.geometry.location.lat,
    //   detailsPlaceFrom.geometry.location.lng,
    //   $event.description
    // ));
  }

  getCountry(detailsPlaceFrom: any) {
    if (detailsPlaceFrom && detailsPlaceFrom?.address_components
      && detailsPlaceFrom?.address_components?.length > 0) {
      const countryComponent = detailsPlaceFrom?.address_components.find((component: any) => {
        return component.types.includes("country");
      });

      return countryComponent ? countryComponent.long_name : null;
    }
    return null
  }

  _fetchAllPlace(place: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.clientsService.searchPlace(place).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res.predictions)
        }
      }).catch((e: any) => {
        resolve([])
      });
    });
    return promise
  }
  _fetchDetailsPlace(placeId: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.clientsService.detailsPlace(placeId).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res)
        }
      }).catch((e: any) => {
        resolve(null)
      });
    });
    return promise
  }

  checkField() {
    if (this.society.name != null && this.society.name != "" &&
      this.society.email != null && this.society.email != "" &&
      this.society.numtel != null && this.society.numtel != "" &&
      this.society.adress != null && this.society.adress != ""

    )
      return true
    else
      return false
  }


  async initUser() {
    this.society = {}

  }

  async addSociety() {
    var isValid = this.checkField()
    if (!isValid) {
      this.isSaved = isValid
      alert("Merci de remplir les champs obligatoire !!!")
    } else {

     // console.log("this.society")
    //  console.log(this.society)
      await this.societyService.subscribe(this.society)
        .then((res: any) => {
        //  console.log("resssssssss : ")
        //  console.log(res)
          if (res) {
          //  console.log("success")
            location.href = '/all-societies-cof'
            this.initUser()
            // if (this.selectedProfiles.includes("TRANSPORTEUR")) {
            //   location.href = '/tracking-user';
            // } else if (this.selectedProfiles.includes("PREPARATEUR")) {
            //   location.href = '/preparers';
            // }
            //this.sacsList = res;
          }
        });

    }
    // console.log("this.selectedProfiles : ")
    // console.log(this.selectedProfiles)
  }


}
