<div class="modal-header">
    <h4 class="modal-title">{{'data.import_excel' | translate}}</h4>
    <div class="page-title-actions">
        <input hidden type="file" (change)="readExcel($event)" multiple="false" #file>
        <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip"
            data-placement="bottom" class="btn-shadow mr-3 btn btn-info">
            <i class="fa fa-star">{{'data.import_excel' | translate}}</i>
        </button>
    </div>

    <button type="button" aria-label="Close" (click)="closeModal()">
        <i class="fa fa-close"></i>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="isLoading; else elseAllBoxes">
        <div class="row">
            <div class="col-md-12">
                <div class="main-card mb-3 card">
                    <div class="card-body text-center">
                        <div class="spinner-container blue-spinner">
                            <mat-progress-spinner mode="indeterminate" [diameter]="40"
                                [strokeWidth]="5"></mat-progress-spinner>
                        </div>
                    </div>
                    <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <ng-template #elseAllBoxes>
        <div class="scrollable-div">
            <div class="row">
                <div class="col-12">
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let item of boxes, let index = index">
                            <div class="main-card mb-12 card">
                                <div class="card-header-tab card-header">

                                    <div class="card-header-title">
                                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                                        Colis {{index+1}} - {{ item?.box['Ref'] }}
                                    </div>
                                    <div class="btn-actions-pane-right">
                                        <button type="button" (click)="deleteBox(index)"
                                            class="btn-shadow mr-3 btn btn-danger">
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>

                                </div>
                                <div class="card-body">
                                    <div class="position-relative row form-group">
                                        <div class="col-sm-3">
                                            <legend for="qrCode" class="space-bottom">{{'data.reference' | translate}}
                                            </legend>
                                            <input name="qrCode" id="qrCode" type="text" disabled class="form-control"
                                                value="{{ item?.box['Ref'] }}">
                                            <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.box?.hasOwnProperty('Ref') || item?.box['Ref']==''">
                                                {{'data.add' | translate}} {{'data.reference' | translate}}
                                            </mat-error>
                                        </div>

                                        <div class="col-sm-6">
                                            <legend for="client" class="space-bottom">{{'data.description' | translate}}
                                            </legend>
                                            <input name="description_{{index}}" id="description_{{index}}" type="text"
                                                class="form-control" [(ngModel)]="item.box.t_seak">
                                            <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.box?.hasOwnProperty('t_seak') || item?.box['t_seak']==''">
                                                {{'data.add'}} {{'data.description'}}
                                            </mat-error>
                                        </div>

                                        <div class="col-sm-3">
                                            <legend for="client" class="space-bottom">{{'data.height' | translate}} (m)</legend>
                                            <input name="originCountryCode" id="originCountryCode" type="number"
                                                disabled class="form-control" value="{{item?.box['t_hght']}}">
                                            <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.box?.hasOwnProperty('t_hght') || item?.box['t_hght']==''">
                                                {{'data.add' | translate}} {{'data.height' | translate}}
                                            </mat-error>
                                        </div>

                                        <div class="col-sm-3">
                                            <legend for="client" class="space-bottom">{{'data.length' | translate}} (m)</legend>
                                            <input name="countryCode" id="countryCode" type="number" disabled
                                                class="form-control" value="{{item?.box['t_wdth']}}">
                                            <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.box?.hasOwnProperty('t_wdth') || item?.box['t_wdth']==''">
                                                {{'data.add' | translate}} {{'data.length' | translate}}
                                            </mat-error>
                                        </div>

                                        <div class="col-sm-3">
                                            <legend for="client" class="space-bottom">{{'data.width' | translate}} (m)</legend>
                                            <input name="postalCode" id="postalCode" type="number" disabled
                                                class="form-control" value="{{item?.box['t_dpth']}}">
                                            <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.box?.hasOwnProperty('t_dpth') || item?.box['t_dpth']==''">
                                                {{'data.add' | translate}} {{'data.width' | translate}}
                                            </mat-error>
                                        </div>

                                        <div class="col-sm-3">
                                            <legend for="client" class="space-bottom">UCS
                                            </legend>
                                            <input name="numTelDest" id="numTelDest" type="number" disabled
                                                class="form-control" value="{{item?.box['UCS']}}">
                                            <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.box?.hasOwnProperty('UCS') || item?.box['UCS']==''">
                                                {{'data.add' | translate}} UCS
                                            </mat-error>
                                        </div>

                                        <div class="col-sm-3">
                                            <legend for="client" class="space-bottom">{{'data.volume' | translate}} (m³)</legend>
                                            <input name="nom_prod" id="nom_prod" type="number" disabled
                                                class="form-control" value="{{item?.box['Volume']}}">
                                            <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.box?.hasOwnProperty('Volume') || item?.box['Volume']==''">
                                                {{'data.add' | translate}} {{'data.volume' | translate}}
                                            </mat-error>
                                        </div>

                                        <div class="col-sm-3">
                                            <legend for="client" class="space-bottom">{{'data.code_pro' | translate}}</legend>
                                            <input name="nom_prod" id="nom_prod" type="number" disabled
                                                class="form-control" value="{{item?.box['Code FNR']}}">
                                            <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.box?.hasOwnProperty('Code FNR') || item?.box['Code FNR']==''">
                                                {{'data.add' | translate}} {{'data.code_pro' | translate}}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-block text-center card-footer"
                                    *ngIf="item?.msg != null && item?.msg.msg!=''">
                                    <div style="color: red;"><b>{{item?.msg?.msg}}</b></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </ng-template>

</div>
<div class="modal-footer">
    <div *ngIf="isSaved; else elseIsSaved">
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="main-card mb-3 card">
                    <div class="card-body text-center"> -->
        <div class="spinner-container blue-spinner">
            <mat-progress-spinner mode="indeterminate" [diameter]="40" [strokeWidth]="5"></mat-progress-spinner>
        </div>
        <!-- </div>
                </div>
            </div>
        </div> -->
    </div>
    <ng-template #elseIsSaved>
        <button type="button" class="btn btn-success" (click)="saveBoxes()">{{'data.save' | translate}}</button>
    </ng-template>
    <button type="button" class="btn btn-secondary" (click)="closeModal()">{{'data.close' | translate}}</button>
</div>