<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.company_contract' | translate}}
                <div class="page-title-subheading">{{'data.add_contract_company' | translate}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <!-- <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="mr-2 btn-icon btn-icon-only btn btn-primary">
                <i class="pe-7s-id"></i>
                Détails du contrat
            </button> -->

            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="mr-2 btn-icon btn-icon-only btn btn-outline-success">
                <i class="pe-7s-download text-success"></i>
                {{'data.download_contract' | translate}}
            </button>
        </div>
    </div>
</div>

<div *ngIf="loading; else elseContractSociety">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #elseContractSociety>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title">
                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                        {{'data.contract_details' | translate}}
                    </div>
                    <div class="btn-actions-pane-right">
                        <button type="button" (click)="addNewFreightRateGroup()" class="mt-1 btn btn-warning">
                            <i class="pe-7s-plus"></i> {{'data.add' | translate}}
                        </button>
                    </div>
                </div>

                <div class="card-body">
                    <div class="container">
                        <div [formGroup]="form">
                            <div formArrayName="freightRates">
                                <div *ngFor="let freightRate of freightRates.controls; let i = index">
                                    <div class="position-relative row form-group" [formGroupName]="i"
                                        style="margin-top: 10px;">
                                        <div class="col-12">
                                            <div class="row form-group">
                                                <div class="col-12 col-md-8">
                                                    <select class="form-control" formControlName="country"
                                                        placeholder="Sélectionnez le pays"
                                                        [class]="(!isSaved && checkValue(freightRate.value.country)) ? 'input-border-danger' : 'input-border'">
                                                        <option>Sélectionnez le pays</option>
                                                        <option [value]="item" *ngFor="let item of countries">
                                                            {{item}}
                                                        </option>
                                                    </select>
                                                    <!-- <input type="text" class="form-control"
                                                    placeholder="Désignation produit" formControlName="country"> -->
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <button type="button" (click)="deleteFreightRateGroup(i)"
                                                        class="mr-2 btn-icon btn-icon-only btn btn-danger float-right">
                                                        <i class="pe-7s-trash btn-icon-wrapper"></i>
                                                    </button>

                                                    <button type="button" (click)="addRanges(i)"
                                                        class="mr-2 btn-icon btn-icon-only btn btn-success float-right">
                                                        <i class="pe-7s-plus btn-icon-wrapper"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12" formGroupName="minimum" style="margin-top: 20px;">
                                            <div class="row form-group">
                                                <div class="col-12 col-md-2 text-center label-center">
                                                    <b>Minimum</b>
                                                </div>
                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Maximum"
                                                        formControlName="max"
                                                        [class]="(!isSaved && checkValue(freightRate.value.minimum.max)) ? 'input-border-danger' : 'input-border'">
                                                </div>
                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control"
                                                        placeholder="{{'data.price' | translate}}"
                                                        formControlName="value"
                                                        [class]="(!isSaved && checkValue(freightRate.value.minimum.value)) ? 'input-border-danger' : 'input-border'">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12" style="margin-top: 10px; ">
                                            <div formArrayName="ranges">
                                                <div *ngFor="let range of getRanges(i).controls; let j = index">
                                                    <div class="col-12" [formGroupName]="j"
                                                        style="margin-bottom: 10px;">
                                                        <div class="row form-group">
                                                            <div class="col-12 col-md-1 float-center">
                                                                <!-- <b>{{j+1}}</b> -->
                                                                <button type="button" (click)="deleteRangeGroup(i, j)"
                                                                    class="mr-2 btn-icon btn-icon-only btn btn-danger float-right"><i
                                                                        class="pe-7s-trash btn-icon-wrapper">
                                                                    </i></button>
                                                            </div>
                                                            <div class="col-12 col-md-2">
                                                                <input type="number" class="form-control"
                                                                    placeholder="Minimum" formControlName="min"
                                                                    [class]="(!isSaved && checkValue(range.value.min)) ? 'input-border-danger' : 'input-border'">
                                                            </div>
                                                            <div class="col-12 col-md-2">
                                                                <input type="number" class="form-control"
                                                                    placeholder="Maximum" formControlName="max"
                                                                    [class]="(!isSaved && checkValue(range.value.max)) ? 'input-border-danger' : 'input-border'">
                                                            </div>
                                                            <div class="col-12 col-md-2">
                                                                <input type="number" class="form-control"
                                                                    placeholder="{{'data.price' | translate}}"
                                                                    formControlName="value"
                                                                    [class]="(!isSaved && checkValue(range.value.value)) ? 'input-border-danger' : 'input-border'">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12" style="margin-bottom: 20px;">
                                            <div class="row form-group">
                                                <div class="col-12 col-md-2 text-center label-center">
                                                    <b>{{'data.by_truck' | translate}}</b>
                                                </div>
                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Maximum"
                                                        formControlName="maximum"
                                                        [class]="(!isSaved && checkValue(freightRate.value.maximum)) ? 'input-border-danger' : 'input-border'">
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-block text-center card-footer">
                    <!-- <ng-template #content let-c="close" let-d="dismiss">
                    <div class="modal-header">
                      <h4 class="modal-title">Basic demo</h4>
                      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                      </button>
                    </div>
                    <div class="modal-body">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a
                        type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                        passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
                      <button type="button" class="btn btn-primary">Save changes</button>
                    </div>
                  </ng-template>
                  <button class="btn btn-primary" (click)="open(content)">Launch demo modal</button> -->
                    <!-- <ng-template #content6 let-c="close" let-d="dismiss">
                    <div class="modal-header">
                      <h4 class="modal-title">Modal title</h4>
                      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                      </button>
                    </div>
                    <div class="modal-body">
                      <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                        Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                      </p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
                      <button type="button" class="btn btn-primary">Save changes</button>
                    </div>
                  </ng-template>
                  <button class="btn btn-success" (click)="openLarge(content6)">Launch demo modal</button> -->
                    <!-- <app-send-mails #content6></app-send-mails> -->
                    <!-- <button class="btn btn-success" (click)="openLarge()">Launch demo modal</button> -->

                    <button type="submit" (click)="addContractSociety()" class="btn btn-primary btn-lg btn-block">
                        {{'data.save_contract' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>