<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <img width="100%" src="https://assets.marketbey.tn/WTM/products/box.png" alt="">
            </div>
            <div>Boxes 
                <div class="page-title-subheading">{{'data.the_list_of_products' | translate}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <!-- <input hidden type="file" (change)="readExcel($event)" multiple="false" #file>
            <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip"
                data-placement="bottom" class="btn-shadow mr-3 btn btn-info">
                <i class="fa fa-star"> Importer fichier excel</i>
            </button> -->
            <div class="d-inline-block dropdown">
                <button type="button" class="btn-shadow mr-3 btn btn-info" *ngIf="idSociety != null"
                    (click)="importBoxesProducts()">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-star"></i>
                    </span>
                    {{'data.import_excel' | translate}}
                </button>
            </div>
            <div class="d-inline-block dropdown">
                <button type="button" class="btn btn-success" *ngIf="idSociety != null" (click)="newBoxProduct()">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    {{'data.add_box' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">{{'data.filtred' | translate}}
                <div class="btn-actions-pane-right">
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;">
                            <div class="form-row">
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="ref" class="">{{'data.reference' | translate}}</legend>
                                        <input type="text" name="ref" id="ref" class="form-control" #autoInput matInput
                                            [matAutocomplete]="auto" (input)="onChange()">

                                        <mat-autocomplete #auto="matAutocomplete"
                                            (optionSelected)="onSelectionChange($event)">
                                            <mat-option *ngFor="let box of boxes" [value]="box">
                                                {{ box?.ref + ' - ' + box?.t_seak }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                                <!-- 
                                <button type="submit" (click)="searchBoxes()"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search"
                                    tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading; else elseAllBoxes">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
                </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseAllBoxes>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">Boxes
                </div>
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="boxes?.length === 0">
                        <h2 class="text-center" style="color: red;">{{'data.no_box' | translate}}</h2>
                    </div>
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                        *ngIf="boxes?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th>{{'data.reference' | translate}}</th>
                                <th>{{'data.description' | translate}}</th>
                                <th class="text-center">{{'data.length' | translate}}<br>(m)</th>
                                <th class="text-center">{{'data.width' | translate}}<br>(m)</th>
                                <th class="text-center">{{'data.height' | translate}}<br>(m)</th>
                                <th class="text-center">UCS</th>
                                <th class="text-center">{{'data.volume' | translate}}<br>(m³)</th>
                                <th class="text-center">{{'data.provider' | translate}}</th>
                                <th class="text-center" *ngIf="idSociety != null">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let box of boxes, let index = index">
                                <td class="text-center text-muted">{{index+1}}</td>
                                <td><b>{{ box?.ref }}</b></td>
                                <td>{{ box?.t_seak }}</td>
                                <td class="text-center"><b>{{ box?.t_wdth | number:'0.3' }}</b></td>
                                <td class="text-center"><b>{{ box?.t_dpth | number:'0.3' }}</b></td>
                                <td class="text-center"><b>{{ box?.t_hght | number:'0.3' }}</b></td>
                                <td class="text-center"><b>{{ box?.ucs }}</b></td>
                                <td class="text-center"><b>{{ box?.volume | number:'0.3' }}</b></td>
                                <td>
                                    <div class="widget-content p-0" *ngIf="box?.provider != null">
                                        <div class="widget-content-wrapper">
                                            <!-- <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle"
                                                    src="assets/images/avatars/2.jpg" alt="">
                                                    <ngx-avatar class="rounded-circle" name="{{box.provider.prenom_client}}
                                                {{box.provider.nom_client}}">
                                                    </ngx-avatar>
                                                </div>
                                            </div> -->
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">
                                                    {{box.provider.providerCode}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{box.provider.providerName}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{box.provider.contactUsername}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center d-block" *ngIf="idSociety != null">
                                    <button type="button" (click)="saveBoxProduct(box)"
                                        class="mr-3 btn-icon btn-icon-only btn btn-outline-primary">
                                        <i class="pe-7s-pen btn-icon-wrapper"> </i>
                                    </button>
                                    <button type="button" (click)="deleteBoxProduct(box?._id)"
                                        class="mr-3 btn-icon btn-icon-only btn btn-outline-danger">
                                        <i class="pe-7s-trash btn-icon-wrapper"> </i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-block text-center card-footer" *ngIf="boxes?.length > 0 && pages?.length > 1">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                                <a class="page-link" tabindex="-1" (click)="previousPage()">
                                    <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                                    {{'data.previous' | translate}}</a>
                            </li>

                            <div *ngFor="let p of pagesDisplaied">
                                <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                    <a *ngIf="currentPage!==p" class="page-link" (click)="selectPage(p)">{{p+1}}</a>

                                    <span *ngIf="currentPage===p" class="page-link">
                                        {{p+1}}
                                        <span class="sr-only">(current)</span>
                                    </span>
                                </li>
                            </div>
                            <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                                <a class="page-link" tabindex="-1" (click)="nextPage()">{{'data.next' | translate}} <i
                                        class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</ng-template>