import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.css']
})
export class EditTeamComponent implements OnInit {
  isLoading: boolean = true
  profiles = ["admin", "simple_user", "tranport_cost"];
  societies: any[] = [];
  idSociety: string;
  isSaved: Boolean = true;
  idUser: string;

  user: any
  constructor(private teamsServices: TeamsService,
    private route: ActivatedRoute,
  ) {
    this.idSociety = this.teamsServices.getIdSociety();
  }

  async ngOnInit() {
    //await this._fetchAllSocieties()
    this.route.queryParams.subscribe((params: any) => {
      this.idUser = params.user;
      this._fetchOneTeam();
    });
  }

  password: string = '';
  passwordFieldType: string = 'password';

  togglePasswordVisibility(): void {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
  async _fetchOneTeam() {
    await this.teamsServices.getTeam(this.idUser).then((res: any) => {
      // console.log("ressssssssssssss teammmmmmmmmmmm")
      //  console.log(res)
      if (res) {
        this.isLoading = false
        this.user = res;
      }
    });
  }

  checkField() {
    if (this.user.nom_user != null && this.user.nom_user != "" &&
      this.user.prenom_user != null && this.user.prenom_user != "" &&
      this.user.email_user != null && this.user.email_user != "" &&
      this.user.tel_user != null && this.user.tel_user != "" &&
      this.user.password != null && this.user.password != "" &&
      this.user.profiles != null && this.user.profiles != ""

    )
      return true
    else
      return false
  }

  async editTeam() {
   // console.log(this.user)
    var isValid = this.checkField()
    if (!isValid) {
      this.isSaved = isValid
      alert("Merci de remplir les champs obligatoire !!!")
    } else {
      await this.teamsServices.editTeam(this.idUser, this.user)
        .then((res: any) => {
         // console.log("resssssssss : ")
        //  console.log(res)
          if (res) {
            if (res?.response) {
              location.href = '/all-teams';
            } else
              alert(res.msg)
          } else
            alert("Probléme reproduit !!!")
        });
    }
  }




}
