import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListCommentsComponent } from 'src/app/components/shared/dialogs/list-comments/list-comments.component';
import { SendMailsComponent } from 'src/app/components/shared/dialogs/send-mails/send-mails.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { SweetalertService } from 'src/app/services/sweetalert/sweetalert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-all-commandes',
  templateUrl: './all-commandes.component.html',
  styleUrls: ['./all-commandes.component.css']
})
export class AllCommandesComponent implements OnInit {

  isLoading: any = true;

  heading = 'Dropdowns';
  subheading = 'Multiple styles, actions and effects are available for the ArchutectUI dropdown buttons.';
  icon = 'pe-7s-umbrella icon-gradient bg-sunny-morning';

  status = ["Selectionnez status", "validated", "transporting_depot", "prepared", "submited", "transporting", "transported", "arrived", "delivered", "paied", "returned", "refused", "canceled"];

  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  cmdList: Array<any> = [];
  statusCmds: any = []

  filter: any = {}

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService,
    private sweetalert: SweetalertService,
    private modalService: NgbModal,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    // this.getInfosUser()
    // this.listCmdsClient()
    this.searchCmds()
  }

  async filterCmds() {
    // console.log(this.filter)
    this.pages = []
    this.pagesDisplaied = []
    this.currentPage = 0
    this.searchCmds(/*ref.value, numero.value, datein.value, datefin.value*/);
  }

  async searchCmds(/*ref, numero, datein, datefin*/) {
    // window.scrollTo(0, 380);

    this.filter.page = this.currentPage
    await this.cmdsService.filterCmds(this.filter).then((res: any) => {
      if (res) {
        this.isLoading = false
        // console.log("resssssssss : ")
        // console.log(res)
        this.cmdList = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i)
        this.splicePages()
      }
    });
  }
  nextPage() {
    this.currentPage++
    this.searchCmds() //ref.value, numero.value, datein.value, datefin.value);
  }

  previousPage() {
    this.currentPage--
    this.searchCmds() //ref.value, numero.value, datein.value, datefin.value);
  }

  selectPage(page) {
    this.currentPage = page
    this.searchCmds() //ref.value, numero.value, datein.value, datefin.value);
  }

  async updateStatusCmd(cmd, status) {
    // this.notif.notify('success', 'Commande mis à jour avec succès');
    // return;
    if (confirm('Etes vous sûre?'))
      await this.cmdsService.updateCmdStatus(cmd, status).then((res) => {
        // console.log(res)
        if (res) {
          alert('Commande mis à jour avec succès');
          cmd.etat_cmd = "canceled"
        }
        else {
          alert("Une erreur s'est produite");
        }
      });
  }

  listComments(cmd) {
    if (cmd.comments.length > 0) {
      const confirmDialog = this.dialog.open(ListCommentsComponent, {
        data: {
          cmd: cmd,
        }
      });
    }
  }

  validateCmd(idCmd: any) {
    this.cmdsService.validateCmds({
      cmds: [idCmd]
    }).then(async (res: any) => {
      // console.log("resssssssss validateCmds : ")
      // console.log(res)
      if (res && res.response) {
        if (res?.data && res?.data?.length > 0) {
          for (var i = 0; i < res?.data?.length; i++) {
            await this.sweetalert.alertProblem(res?.data[i]?.msg)
          }
        } else {
          this.sweetalert.alertSuccess("Toutes les commandes sont validées").then((result: any) => {
            if (result.isConfirmed) {
              this.searchCmds()
            }
          })
        }
      } else {
        alert(res.msg);
      }
    })
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  selectChangeStatus(event: any) {
    if (event.target.value == "Selectionnez status") {
      this.filter.status = null
    } else {
      this.filter.status = event.target.value;
    }
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if (this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if (this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if (this.currentPage >= 5 && this.currentPage < this.pages.length - 5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage - 4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length - 10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  sendMail(cmd: any) {
    // console.log("cmd email")
    // console.log(cmd)
    const modalRef = this.modalService.open(SendMailsComponent, { size: 'lg' });
    modalRef.componentInstance.providerContacts = this.initProviderContacts(cmd)
    modalRef.componentInstance.transContacts = this.initTransContacts(cmd)
    modalRef.componentInstance.cmds = [cmd?._id]
    modalRef.componentInstance.result.subscribe((receivedEntry) => {
      // console.log(receivedEntry)
      if (receivedEntry) {
        console.log("Result from modal:", receivedEntry);
      }
    });
  }

  initProviderContacts(cmd: any) {
    let providerContacts: any[] = []
    providerContacts.push({ email: cmd?.client_cmd?.contactUsername, isChecked: false })
    cmd?.client_cmd?.contacts?.forEach(contact => {
      providerContacts.push({ email: contact?.email, isChecked: false })
    });
    // console.log("providerContacts");
    // console.log(providerContacts);
    return providerContacts
  }

  initTransContacts(cmd: any) {
    let transContacts: any[] = []
    transContacts.push({ email: cmd?.client_cmd?.pickUpCollector?.transporter_id?.email, isChecked: false })
    cmd?.client_cmd?.pickUpCollector?.transporter_id?.contacts?.forEach(contact => {
      transContacts.push({ email: contact?.email, isChecked: false })
    });

    // console.log("transContacts");
    // console.log(transContacts);
    return transContacts
  }

  deleteCmd(idCmd: any) {
    this.sweetalert.confirmActionBtn("Êtes-vous sûr de supprimer l'enlèvement ?")
      .then((result: any) => {
        if (result.isConfirmed) {
          this.cmdsService.deleteCmd(idCmd).then((res: any) => {
            if (res) {
              // console.log("successssssssss")

              this.sweetalert.alertSuccess("La commande a été supprimé avec succès.").then(async (result: any) => {
                if (result.isConfirmed) {
                  this.searchCmds()
                }
              })
            }
          });
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          this.sweetalert.alertCanceled().then((res: any) => {
          })
        }
      })
  }

  validateTakeOff(cmd) {
    if (cmd?.etat_cmd == "validated")
      this.sweetalert.confirmActionBtn("Vous pourrez confirmer l'Enlèvement de la marchandise par la société de transport !")
        // + this.cmd?.pickupedBy?.nameSociety + " !")
        .then((result: any) => {
          if (result.isConfirmed) {
            this.cmdsService.confirmPickup(cmd?._id).then((res: any) => {
              if (res) {
                // console.log("successssssssss")

                this.sweetalert.alertSuccess("La commande a été enlevé avec succès.").then(async (result: any) => {
                  if (result.isConfirmed) {
                    this.searchCmds()
                  }
                })
              }
            });
            // this.cmdsService.updateCmdStatus(cmd?._id, "take_off").then((res: any) => {
            //   if (res) {
            //     // console.log("successssssssss")

            //     this.sweetalert.alertSuccess("La commande a été enlevé avec succès.").then(async (result: any) => {
            //       if (result.isConfirmed) {
            //         this.listCmdsClient(this.statusCmds, this.isPaied)
            //       }
            //     })
            //   }
            // });
            // this.sweetalert.alertConfirmed().then((res: any) => {
            //   // location.href = "/product/product-list"
            //   this.allMissionDrivers(this.filterDate)
            // })
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            this.sweetalert.alertCanceled().then((res: any) => {
              // location.href = "/product/product-list"
            })
          }
        })
  }

  validateAtDepot(cmd) {
    if (cmd?.etat_cmd == "take_off") {
      this.sweetalert.confirmActionBtn("Vous pourrez confirmer si l'enlèvement au dépot !")
        // + this.cmd?.pickupedBy?.nameSociety + " !")
        .then((result: any) => {
          if (result.isConfirmed) {
            this.cmdsService.updateCmdStatus(cmd?._id, "transporting_depot").then((res: any) => {
              // console.log("successssssssss")
              // console.log(res)
              if (res) {
                this.sweetalert.alertSuccess("L'enlèvement au dépot.").then(async (result: any) => {
                  if (result.isConfirmed) {
                    this.searchCmds()
                  }
                })
              }
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            this.sweetalert.alertCanceled().then((res: any) => {
              // location.href = "/product/product-list"
            })
          }
        })
    } else {
      alert("Merci de verifié le status de la commande !")
    }
  }
}
