import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SacsService } from 'src/app/services/sacs/sacs.service';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-details-louagiste',
  templateUrl: './details-louagiste.component.html',
  styleUrls: ['./details-louagiste.component.css']
})
export class DetailsLouagisteComponent implements OnInit {

  idLouagiste: string = ""
  sacsLouagiste: any = []
  louagiste: any = {}

  constructor(
    private route: ActivatedRoute,
    private sacsServices: SacsService,
    private userTrackingService: UsersTrackingService
    ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.idLouagiste = params.louagiste;
      //this._fetchLouagiste()
      //this._fetchSacsLouagiste();
    });
  }

  // _fetchSacsLouagiste() {
  //   this.sacsServices.getSacsFromLouagiste(this.idLouagiste).then((sacs) => {
  //     if (sacs) {
  //       this.sacsLouagiste = sacs;
  //     }
  //   });
  // }

  // _fetchLouagiste() {
  //   this.userTrackingService.getLouagiste(this.idLouagiste).then((louag) => {
  //     // console.log("louag")
  //     // console.log(louag)
  //     if (louag) {
  //       this.louagiste = louag;
  //     }
  //   });
  // }

}
