<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <img width="100%" src="https://assets.marketbey.tn/WTM/products/pallet.png" alt="">
            </div>
            <div>{{'data.pallets' | translate}}
                <div class="page-title-subheading">La liste des palettes.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <button type="button" class="btn btn-success" (click)="newPallet()">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    {{'data.add_new_pallet' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading; else elseAllPllets">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
                </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseAllPllets>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">{{'data.pallets' | translate}}
                </div>
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="pallets?.length === 0">
                        <h5 class="text-center text-danger">{{'data.no_pallet' | translate}}</h5>
                    </div>
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                        *ngIf="pallets?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th>{{'data.name' | translate}}</th>
                                <th class="text-center">{{'data.length' | translate}}<br>(m)</th>
                                <th class="text-center">{{'data.width' | translate}}<br>(m)</th>
                                <th class="text-center">{{'data.height' | translate}}<br>(m)</th>
                                <th class="text-center">{{'data.volume' | translate}}<br>(m³)</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pallet of pallets, let index = index">
                                <td class="text-center text-muted">{{index+1}}</td>
                                <td>{{ pallet?.name }}</td>
                                <td class="text-center"><b>{{ pallet?.longueur }}</b></td>
                                <td class="text-center"><b>{{ pallet?.largeur }}</b></td>
                                <td class="text-center"><b>{{ pallet?.hauteur }}</b></td>
                                <td class="text-center"><b>{{ pallet?.volume }}</b></td>
                                <td class="text-center d-block ">
                                    <button type="button" (click)="savePallet(pallet)"
                                        class="mr-3 btn-icon btn-icon-only btn btn-outline-primary">
                                        <i class="pe-7s-pen btn-icon-wrapper"> </i>
                                    </button>
                                    <button type="button" (click)="deletePallet(pallet?._id)"
                                        class="mr-3 btn-icon btn-icon-only btn btn-outline-danger">
                                        <i class="pe-7s-trash btn-icon-wrapper"> </i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-template>