import { Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild } from '@angular/core';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

import { select } from '@angular-redux/store';
import * as $ from 'jquery';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';
import { Router } from '@angular/router';
import { ThemeOptions } from 'src/app/theme-options';
import { interval, Observable, Subscription } from 'rxjs';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;

  lang: any
  selectedBox: any
  showList: any = false;
  langauges: any[] = [
    {
      img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.kl7GhvZW1_zkEFGgwpR9iAHaE8%26pid%3DApi&f=1&ipt=db85b4ca3ecf02bd95b525e19aaad1ac68dcebe9515b560f9a05d75f87dfcb31&ipo=images",
      lang: "data.frensh",
      val: "fr"
    },
    {
      img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.e_bbO_MwobphE7AiIzUzyQHaEA%26pid%3DApi&f=1&ipt=78a47fd578257f340440ce8dc9ff2af23e0926ad8ef80d62e293785c75848053&ipo=images",
      lang: "data.english",
      val: "en"
    }
  ]

  user: any;
  // @ViewChild('native') native: ElementRef;
  isConnected = false
  // allComments: any = []
  allDemandes: any = []
  allPlanifiedDemandes: any = []

  constructor(private cmdsService: CommandesService,
    private teamService: TeamsService,
    private router: Router,
    public globals: ThemeOptions,
    private transServices: TransportorsService,
    private translate: TranslateService,
    private clientService: ClientsService,) {
    this.isConnected = this.teamService.isConnected()
    this.initInfosUser()
    this.initLanguageTranslate()
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  // subscription: Subscription;
  // intervalId: number = null;

  emptyReturn: number = 0


  async ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    // await this.fetchComments()

    // const source = interval(1000);
    // this.subscription = source.subscribe(val => {
    this.fetchPendingDemandes()
    this.lisPendingReturns()
    this.fetchPendingPlanifiedDemandes()
    // });
  }

  async initInfosUser() {
    this.user = this.teamService.getCurrentUserValue()
    // console.log(this.user)
  }

  async lisPendingReturns() {
    await this.transServices.getPendingEmptyReturn().then((res: any) => {
      if (res) {
        this.emptyReturn = res;
      }
    });
  }

  // lang = localStorage.getItem('lang');
  selectLanguage(event: any) {
    // localStorage.setItem('lang', event.target.value);

    this.clientService.setLanguage(event.target.value)
    this.translate.use(event.target.value);
  }

  initLanguageTranslate() {
    this.lang = this.clientService.getLanguage()// localStorage.getItem('lang');
    console.log(this.lang)
    this.selectedBox = this.langauges.find(item => item?.val === this.lang)
    this.translate.setDefaultLang((this.lang) ? this.lang : 'en');
    // if (!lang) {
    //   this.translate.setDefaultLang('en');
    // } else {
    //   this.translate.setDefaultLang(lang);
    // }
  }

  up() {
    this.showList = !this.showList;
  }

  change(box: any) {
    this.selectedBox = box
    this.clientService.setLanguage(this.selectedBox.val)
    this.translate.use(this.selectedBox.val)
    this.showList = !this.showList;
  }
  dateDiff(fromDate) {
    if (fromDate) {
      var dayDiff = Math.floor((Math.floor(Date.now()) - fromDate)/* / (1000 * 60)*/);
      var seconds = Math.floor((dayDiff / 1000) % 60);
      var minutes = Math.floor((dayDiff / (1000 * 60)) % 60);
      var hours = Math.floor((dayDiff / (1000 * 60 * 60)) % 24);

      var hoursStr = (hours < 10) ? "0" + hours : hours;
      var minutesStr = (minutes < 10) ? "0" + minutes : minutes;
      var secondsStr = (seconds < 10) ? "0" + seconds : seconds;

      return hoursStr + ":" + minutesStr// + ":" + secondsStr
      // return {
      //   dayDiff: dayDiff,
      //   dayDiffText: hoursStr + ":" + minutesStr + ":" + secondsStr
      // };
    }
  };

  alertPlanifiedDemande(fromDate, index) {
    var css = ""
    if (fromDate) {
      var dayDiff = Math.floor((fromDate - Math.floor(Date.now())) / (1000 * 60 * 60));

      if (dayDiff < 24 && dayDiff > 5) {
        css = "bg-tr-warning"
      } else if (dayDiff < 5) {
        css = "bg-tr-danger"
      } else {
        css = ""
      }
    }

    return (index % 2 === 1) ? ('dropdown-item ' + css) : ('dropdown-item dropdown-item-unread ' + css)
  }

  // async fetchComments() {
  //   await this.cmdsService.allComments().then((res: any) => {
  //     // console.log("fetchComments");
  //     // console.log(res);
  //     if (res) {
  //       this.allComments = res;
  //     }
  //   });
  // }

  async fetchPendingDemandes() {
    await this.transServices.getPendingDemandes().then((res: any) => {
      // console.log("fetchComments");
      // console.log(res);
      if (res) {
        this.allDemandes = res;
      }
    });
  }

  async fetchPendingPlanifiedDemandes() {
    await this.transServices.getPlanifiedDemandes().then((res: any) => {
      // console.log("fetchPendingPlanifiedDemandes");
      // console.log(res);
      if (res) {
        this.allPlanifiedDemandes = res;
      }
    });
  }

  async deconnexion() {
    await this.teamService.deconnexion()
    // this.router.navigate(['/home'])
    window.location.reload()
    this.router.navigate(['/login'])
  }

  toggleSidebar() {
    // console.log("toggleSidebar")
    // console.log(this.globals.toggleSidebar)
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    // console.log("sidebarHover")
    // console.log(this.globals.sidebarHover)
    this.globals.sidebarHover = !this.globals.sidebarHover;
    // if(this.globals.sidebarHover) {
    //   this.globals.toggleSidebar = true
    //   this.globals.sidebarHover = false
    // }
  }

  toggleSidebarMobile() {
    // console.log("toggleSidebarMobile")
    // console.log(this.globals.toggleSidebarMobile)
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
    if (this.globals.toggleSidebarMobile) {
      this.globals.toggleHeaderMobile = false
    }
  }

  toggleHeaderMobile() {
    // console.log("toggleHeaderMobile")
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
    if (this.globals.toggleHeaderMobile) {
      this.globals.toggleSidebarMobile = false
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }
}
