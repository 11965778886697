import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

const tokenValue = "token-cofat"

@Injectable({
  providedIn: 'root'
})

export class TeamsService {

  constructor(private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router) { }

  uploadPhoto(formData: any) {
    return new Promise((slv) => {
      this.http.post(environment.apiUploadPhoto, formData).subscribe(
        (data: any) => {
          // console.log("uploadPhoto")
          // console.log(data)
          if (data) {
            slv(data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  isConnected() {
    var token = localStorage.getItem(tokenValue)
    // console.log("token : " + token)
    if (token)
      return true;

    return false
  }

  getUserInfos() {
    var token = localStorage.getItem(tokenValue)
    return new Promise((solve) => {
      if (token && token != null) {
        var decoded = jwt_decode(token);
        // console.log("decoded")
        // console.log(decoded)
        this.http.post(environment.apiInfosUser, { "teamid": decoded["id"] }).subscribe((res: any) => {
          // console.log("hajerrrrrrrrrrrrrrrrrrrrrr get info user");
          // console.log(res);
          if (res && res.response && res.data) {
            solve(res.data);
          } else
            solve(true);
        },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            solve(false);
          })
      } else {
        solve(false);
      }
    });
  }

  public getCurrentUserValue(): any {
    const valueToken = localStorage.getItem(tokenValue)
    if (valueToken != null) {
      return this.jwtHelper.decodeToken(valueToken!);
    }

    return null
  }

  decodeToken(): any {
    const valueToken = localStorage.getItem(tokenValue)
    if (valueToken != null) {
      return this.jwtHelper.decodeToken(valueToken!);
    } else {
      this.deconnexion()
    }
  }

  public getIdUser() {
    var decodeToken = this.decodeToken()
    if (decodeToken["id"])
      return decodeToken.id
    else {
      this.deconnexion()
    }
  }

  public getIdSociety() {
    var decodeToken = this.decodeToken()
    if (decodeToken["society"]) {
      return decodeToken.society
    }
  }

  public getEmail() {
    var decodeToken = this.decodeToken()
    if (decodeToken["email"]) {
     // console.log("decodeToken")
     // console.log(decodeToken["email"])
      return decodeToken.email
    } else return ""
  }

  public isAdmin() {
    var decodeToken = this.decodeToken()
    if (decodeToken != null && decodeToken["profiles"] != null && decodeToken["profiles"].includes("admin")) {
      return true
    } else {
      return false
    }
  }

  saveUserCreds(usercreds) {
    localStorage.setItem(tokenValue, JSON.stringify(usercreds.token));
  }

  subscribe(user) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddUser, user)
        .subscribe(
          (data: any) => {
           // console.log("data service")
           // console.log(data)
            // if (data && data.response) {
            slv(data);
            // } else slv(false);
          },
          (error) => {
           // console.log("Cmd error")
           // console.log(error)
            slv(null);
          }
        );
    });
  }

  allSocieties() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllSocieties, {
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  login(creds) {
    // console.log("creds");
    // console.log(creds);
    return new Promise((solve) => {
      this.http.post(environment.apiUsersAuth, creds).subscribe((res: any) => {
        // console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        // console.log(res);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            solve(true);
          } else {
            solve(false);
          }
        }
      })
    });
  }
  
  async searchTeam(filter) {
    return await new Promise((slv) => {
      this.http.post(environment.apiSearchTeam, filter).subscribe(
        (data: any) => {
          // console.log("dataaaaaaaaaaa : ")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getTeam(idUser) {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetOneUser + "/" + idUser, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  editTeam(idUser, user) {
    return new Promise((slv) => {
      this.http.post(environment.apiEditUser, {
        idUser: idUser,
        user: user
      })
        .subscribe(
          (data: any) => {
           // console.log(data)
            // if (data && data.response) {
            slv(data);
            // } else slv(false);
          },
          (error) => {
          //  console.log("edit error")
          //  console.log(error)
            slv(null);
          }
        );
    });
  }

  async getTeams() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllTeams, {
        society: this.getIdSociety()
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  deleteTeam(idUser) {
    return new Promise((slv) => {
      this.http.post(environment.apiDeleteUser, {
        idUser
      }).subscribe(
        (res: any) => {
          slv(res);
        },
        (error) => {
          slv(null);
        }
      );
    });

  }

  deconnexion() {
    localStorage.removeItem(tokenValue);

    window.location.reload()
    this.router.navigate(['/login'])
  }
}
