<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-diamond icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.Business_account' | translate}}
                <div class="page-title-subheading">{{'data.add_business_account' | translate}}.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-12 card">
                <div class="card-body">
                    <form class="" action="" method="post" onsubmit="return false;">
                        <div class="row form-group">
                            <div class="col-12">
                                <div class="form-group">
                                    <legend for="socities" class="">{{'data.company' | translate}}</legend>
                                    <select name="socities" id="socities" class="form-control"
                                        [(ngModel)]="selectedSociety">
                                        <option [ngValue]="null">
                                            {{'data.select_company' | translate}}
                                        </option>
                                        <option *ngFor="let society of allSocties" [ngValue]="society._id"
                                            ngDefaultControl>{{society?.nom_user}}
                                        </option>
                                    </select>
                                    <mat-error style="color: red;font-weight: bold;" *ngIf="selectedSociety==null || selectedSociety=='null'">
                                        {{'data.field_required' | translate}}
                                    </mat-error>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="submit" (click)="addBusinessAccount()"
                                class="btn btn-primary btn-lg btn-block">
                                {{'data.add_business_account' | translate}}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>