<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-add-user icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.company' | translate}}
                <!-- <div class="page-title-subheading">Ajouter un nouveau utilisateur (TRANSPORTEUR /
                    PREPARATEUR / LOUAGISTE).
                </div> -->
                <div class="page-title-subheading">{{'data.add_society' | translate}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <!-- <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button> -->
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="main-card mb-12 card">
        <div class="card-body">
            <form class="" action="" method="post">
                <div class="row">
                    <div class="col-12 col-md-4 form-group">
                        <legend for="name" class="">{{'data.company' | translate}} *</legend>
                        <input name="name" type="text" class="form-control" [(ngModel)]="society.name"
                            [class]="(!isSaved && society.name=='') ? 'input-border-danger' : ''">
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <legend for="email" class="">{{'data.email' | translate}} *</legend>
                        <input name="email" type="text" class="form-control" [(ngModel)]="society.email"
                            [class]="(!isSaved && society.email=='') ? 'input-border-danger' : ''">
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <legend for="secondEmail" class="">{{'data.email' | translate}} 2</legend>
                        <input name="secondEmail" type="text" class="form-control" [(ngModel)]="society.secondEmail">
                    </div>

                </div>
                <div class="row">
                    <div class="col-12 col-md-4 form-group">
                        <div class="position-relative form-group">
                            <legend for="numtel" class="">{{'data.phone_number' | translate}} *</legend>
                            <input name="numtel" type="number" class="form-control" [(ngModel)]="society.numtel"
                                name="numtel"
                                [class]="(!isSaved && society.numtel?.toString()=='') ? 'input-border-danger' : ''">

                        </div>
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <legend for="secondNumtel" class="">{{'data.phone_number' | translate}} 2</legend>
                        <input name="secondNumtel" type="number" class="form-control"
                            [(ngModel)]="society.secondNumtel">
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <legend for="webSite" class="">{{'data.web_site' | translate}}</legend>
                            <input name="webSite" type="text" class="form-control" [(ngModel)]="society.webSite">
                        </div>
                    </div>

                </div>
                <div class="form-row">
                    <div class="col-md-6 form-group">
                        <legend for="adress" class="">{{'data.main_address' | translate}} *</legend>

                        <input type="text" name="adress" id="adress" class="form-control" #autoInput matInput
                            [matAutocomplete]="auto" (input)="onChange('adress')"
                            [class]="(!isSaved && !society.adress) ? 'input-border-danger' : ''">

                        <mat-autocomplete #auto="matAutocomplete"
                            (optionSelected)="onSelectionChange($event, 'adress')">
                            <mat-option *ngFor="let place of places" [value]="place">
                                {{ place.description }}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <legend for="pays" class="">{{'data.country' | translate}} *</legend>
                        <input name="pays" type="text" class="form-control" [(ngModel)]="society.pays"
                            [class]="(!isSaved && society.pays?.toString()=='') ? 'input-border-danger' : ''">

                        <!-- <select class="form-control" name="pays" [(ngModel)]="society.pays" placeholder="Sélectionnez le pays"
                            [class]="(!isSaved && (society.pays=='' || society.pays==null)) ? 'input-border-danger' : ''">
                            <option [value]="item" *ngFor="let item of countries">
                                {{item}}
                            </option>
                        </select> -->
                    </div>
                    <!-- <div class="col-12 col-md-4 form-group">
                        <legend for="adresse" class="">Adresse *</legend>
                        <input name="adresse" type="text" class="form-control" [(ngModel)]="society.adress"
                            [class]="(!isSaved && society.adress?.toString()=='') ? 'input-border-danger' : ''">
                    </div> -->

                    <!-- 
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <legend for="logo" class="">Logo</legend>
                            <input name="logo" type="file" class="form-control" [(ngModel)]="society.logo">
                        </div>
                    </div> -->

                </div>

                <div class="position-relative row form-check">
                    <div class="col-sm-10 offset-sm-10">
                        <button type="submit" (click)="addSociety()" class="btn btn-primary">{{'data.add_company' |
                            translate}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>