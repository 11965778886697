<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-users icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.users' | translate}}
                <div class="page-title-subheading">{{'data.list_of_users' | translate}}
                </div>
            </div>
        </div>

        <div class="page-title-actions">

            <div class="d-inline-block dropdown">
                <button type="button" class="btn btn-success" [routerLink]="['/add-team']">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    {{'data.add_user' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- <div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;">
                            <div class="form-row">
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="name" class="">Nom</legend>
                                        <input name="name" id="name" type="text" class="form-control"
                                            [(ngModel)]="filter.name">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="numero" class="">Numéro de téléphone
                                        </legend>
                                        <input name="numero" id="numero" type="text" class="form-control"
                                            [(ngModel)]="filter.numtel">
                                    </div>
                                </div>

                                <button type="submit" (click)="filterTeams()"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search"
                                    tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div *ngIf="isLoading; else elseAllTeams">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
            </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseAllTeams>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">{{'data.users' | translate}}
                    <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
                </div>
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="teamsList?.length === 0">
                        <h2 class="text-center" style="color: red;">{{'data.no_user' | translate}}</h2>
                    </div>
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                        *ngIf="teamsList?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <!-- <th class="text-center"></th> -->
                                <th>{{'data.user' | translate}}</th>
                                <th>{{'data.email' | translate}}</th>
                                <th>{{'data.profile' | translate}}</th>
                                <th class="text-center">{{'data.phone_number' | translate}}</th>
                                <!-- <th class="text-center">Société</th> -->

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let team of teamsList, let index = index">
                                <td class="text-center text-muted">{{index+1}}</td>
                                <!-- <td>
                                    <div *ngIf="!team?.isValid" class="text-center invalid-provider">
                                        <div style="margin-top: auto; margin-bottom: auto;">
                                            <i class="fa fa-close"></i>
                                        </div>
                                    </div>
                                </td> -->
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <!-- <img width="40" class="rounded-circle"
                                                    src="assets/images/avatars/2.jpg" alt=""> -->
                                                    <ngx-avatar class="rounded-circle" name="{{team.prenom_user}}
                                                {{team.nom_user}}">
                                                    </ngx-avatar>
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-subheading opacity-7">
                                                    {{team.prenom_user}}
                                                    {{team.nom_user}}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    {{team?.email_user }}
                                </td>
                                <td>
                            <tr *ngFor="let element of team.profiles, let index = index">
                                <td>{{ element }}</td>
                            </tr>

                            </td>
                            <td class="text-center">

                                {{team?.tel_user}}

                            </td>
                            <!-- <td class="text-center">
                                {{ team.society?.name}}
                            </td> -->
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>

                                        <!-- <button type="button" [routerLink]="['/detail-cmds-client']"
                                            [queryParams]="{ client: element.client._id }" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Details
                                        </button> -->
                                        <button type="button" [routerLink]="['/edit-team']"
                                            [queryParams]="{ user: team._id }" tabindex="0" class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-pen text-primary"
                                                style="margin-right: 10px;"></i>{{'data.edit_user' | translate}}
                                        </button>
                                        <button type="button" (click)="deleteTeam(team._id )" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-trash text-danger"
                                                style="margin-right: 10px;"></i>{{'data.delete_user' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
            </div>
        </div>
    </div>
</ng-template>