<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-culture icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.companies' | translate}}
                <div class="page-title-subheading">{{'data.list_company' | translate}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <button type="button" class="btn btn-warning" [routerLink]="['/add-society']">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    {{'data.add_company' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading; else elseAllSocieties">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
            </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseAllSocieties>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">{{'data.companies' | translate}}
                    <!-- <div class="btn-actions-pane-right">
                        <div role="group" class="btn-group-sm btn-group">
                            <button class="active btn btn-focus">Last Week</button>
                            <button class="btn btn-focus">All Month</button>
                        </div>
                    </div> -->
                </div>
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="allSocties.length === 0">
                        <h5 class="text-center">{{'data.no_company'}}</h5>
                    </div>
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                        *ngIf="allSocties.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th>{{'data.company' | translate}}</th>
                                <th class="text-center">{{'data.phone_number' | translate}}</th>
                                <th class="text-center">{{'data.address' | translate}}</th>
                                <th class="text-center">{{'data.number_requests' | translate}}</th>
                                <th class="text-center">{{'data.status' | translate}}</th>
                                <!-- <th class="text-center">Pick UP</th> -->
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let society of allSocties, let index = index">
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" height="40"
                                                        src="{{'https://assets.marketbey.tn/Elloumi/trans-societies/logo/' + society?.logo}}"
                                                        alt="" style="border: 1px solid #000;">
                                                    <!-- <ngx-avatar class="rounded-circle" name="{{society.user_transportor.prenom_user}}
                                                        {{society.user_transportor.nom_user}}">
                                                     </ngx-avatar> -->
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">
                                                    {{society?.nameSociety}}
                                                    <!-- {{society?.prenom_user}} -->
                                                    <!-- {{society?.nom_user}} -->
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{society?.emailSociety}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <b>{{society?.numTelSociety}}</b>
                                </td>
                                <td>
                                    <div *ngFor="let adress of society?.adresses, let index = index">
                                        <b>{{(index+1) + "- " + adress?.label_target+ ", "+ adress?.country}}</b>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <b>{{society?.pickupedCmds}}</b>
                                </td>
                                <td class="text-center">
                                    <div class="badge badge-success">
                                        Validé
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                        <button class="btn-wide btn btn-info">Actions</button>
                                        <button type="button" ngbDropdownToggle
                                            class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                class="sr-only">Actions</span></button>
                                        <div ngbDropdownMenu>
                                            <button type="button" [routerLink]="['/contract-society']"
                                                [queryParams]="{ society: society._id }" tabindex="0"
                                                class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-id text-warning"
                                                    style="margin-right: 10px;"></i>{{'data.contract_details' |
                                                translate}}
                                            </button>
                                            <button type="button" [routerLink]="['/details-society']"
                                                [queryParams]="{ society: society._id }" tabindex="0"
                                                class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-news-paper"
                                                    style="margin-right: 10px;"></i>{{'data.company_details' |
                                                translate}}
                                            </button>
                                            <button type="button" [routerLink]="['/edit-society']"
                                                [queryParams]="{ society: society._id }" tabindex="0"
                                                class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-pen text-primary"
                                                    style="margin-right: 10px;"></i>{{'data.edit_company' | translate}}
                                            </button>
                                            <button type="button" (click)="deleteSociety(society._id)" tabindex="0"
                                                class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-trash text-danger"
                                                    style="margin-right: 10px;"></i>{{'data.delete_company' |
                                                translate}}
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-template>