import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendMailsComponent } from 'src/app/components/shared/dialogs/send-mails/send-mails.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { SweetalertService } from 'src/app/services/sweetalert/sweetalert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-status-commandes',
  templateUrl: './status-commandes.component.html',
  styleUrls: ['./status-commandes.component.css']
})
export class StatusCommandesComponent implements OnInit {

  isLoading: any = true;

  statusCmds: Array<string> = []
  isPaied: boolean = null
  listCmds: any = []

  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  statusCmd: any = []
  cmdsListToAffect: any = [];

  constructor(private route: ActivatedRoute,
    private modalService: NgbModal,
    private sweetalert: SweetalertService,
    private cmdsService: CommandesService,
    private clientService: ClientsService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmd = lst
      }
    )

    this.route.queryParams.subscribe((params) => {
      this.statusCmds = params.status;
      this.isPaied = params.paied
      // console.log("params.status")
      // console.log(params.status)
      // console.log("params.paied")
      // console.log(params.paied)
      this.listCmdsClient(this.statusCmds, this.isPaied)
    });
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  includes(array) {
    for (var i = 0; i < array.length; i++) {
      if (this.statusCmds.includes(array[i])) {
        return true
      }
    }
    return false
  }

  async listCmdsClient(status, isPaied) {
    await this.cmdsService.allCmdsClientByStatus(this.currentPage, null, [status], isPaied, null).then((res: any) => {
      // console.log("res listCmdsClient")
      // console.log(res)
      if (res) {
        this.isLoading = false
        this.listCmds = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i)
        this.splicePages()
      }
    });
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if (this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if (this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if (this.currentPage >= 5 && this.currentPage < this.pages.length - 5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage - 4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length - 10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  nextPage() {
    this.currentPage++
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

  previousPage() {
    this.currentPage--
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

  selectPage(page) {
    this.currentPage = page
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

  validateCmds(cmds: any) {
    // console.log(cmds)
    this.cmdsService.validateCmds({
      cmds: cmds
    }).then(async (res: any) => {
    //  console.log("resssssssss validateCmds : ")
    //  console.log(res)
      if (res && res.response) {
        if (res?.data && res?.data?.length > 0) {
          for (var i = 0; i < res?.data?.length; i++) {
            await this.sweetalert.alertProblem(res?.data[i]?.msg)
          }
        } else {
          this.sweetalert.alertSuccess("Toutes les commandes sont validées").then((result: any) => {
            if (result.isConfirmed) {
              this.listCmdsClient(this.statusCmds, this.isPaied)
            }
          })
        }
      } else {
        alert(res.msg);
      }
    })
  }

  isAllCmdsChecked() {
    if (this.cmdsListToAffect.length == 0)
      return false
    else
      return true
  }
  itemIsAffected(idCmd) {
    return this.cmdsListToAffect.some(function (el) {
      return el === idCmd;
    });
  }
  addALLCmdToAffect(event: any) {
    if (event.target.checked) {
      this.listCmds.forEach(cmd => {
        this.cmdsListToAffect.push(cmd?._id)
      });
    } else {
      this.cmdsListToAffect = [];
    }
  }
  addCmdToAffect(event: any, cmd) {
    if (event.target.checked) {
      this.cmdsListToAffect.push(cmd?._id)
    } else {
      this.cmdsListToAffect = this.cmdsListToAffect.filter(item => item !== cmd);
    }
  }

  sendMail(cmd: any) {
    const modalRef = this.modalService.open(SendMailsComponent, { size: 'lg' });
    modalRef.componentInstance.providerContacts = this.initProviderContacts(cmd)
    modalRef.componentInstance.transContacts = this.initTransContacts(cmd)
    modalRef.componentInstance.cmds = [cmd?._id]

    modalRef.componentInstance.result.subscribe((receivedEntry) => {
      // console.log(receivedEntry)
      if (receivedEntry) {
        // console.log("Result from modal:", receivedEntry);
      }
    });
  }
  initProviderContacts(cmd: any) {
    let providerContacts: any[] = []
    providerContacts.push({ email: cmd?.client_cmd?.contactUsername, isChecked: false })
    cmd?.client_cmd?.contacts?.forEach(contact => {
      providerContacts.push({ email: contact?.email, isChecked: false })
    });

    return providerContacts
  }
  initTransContacts(cmd: any) {
    let transContacts: any[] = []
    transContacts.push({ email: cmd?.client_cmd?.pickUpCollector?.transporter_id?.email, isChecked: false })
    cmd?.client_cmd?.pickUpCollector?.transporter_id?.contacts?.forEach(contact => {
      transContacts.push({ email: contact?.email, isChecked: false })
    });

    return transContacts
  }

  deleteCmd(idCmd: any) {
    this.sweetalert.confirmActionBtn("Êtes-vous sûr de supprimer l'enlèvement ?")
      .then((result: any) => {
        if (result.isConfirmed) {
          this.cmdsService.deleteCmd(idCmd).then((res: any) => {
            if (res) {
              if (res?.response) {
                this.sweetalert.alertSuccess("La commande a été supprimé avec succès.").then(async (result: any) => {
                  if (result.isConfirmed) {
                    this.listCmdsClient(this.statusCmds, this.isPaied)
                  }
                })
              } else {
                alert(res?.msg)
              }
            }
          });
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          this.sweetalert.alertCanceled().then((res: any) => {
          })
        }
      })
  }

  validateTakeOff(cmd) {
    if (cmd?.etat_cmd == "validated")
      this.sweetalert.confirmActionBtn("Vous pourrez confirmer l'Enlèvement de la marchandise par la société de transport !")
        // + this.cmd?.pickupedBy?.nameSociety + " !")
        .then((result: any) => {
          if (result.isConfirmed) {
            this.cmdsService.confirmPickup(cmd?._id).then((res: any) => {
              if (res) {
                // console.log("successssssssss")

                this.sweetalert.alertSuccess("La commande a été enlevé avec succès.").then(async (result: any) => {
                  if (result.isConfirmed) {
                    this.listCmdsClient(this.statusCmds, this.isPaied)
                  }
                })
              }
            });
            // this.cmdsService.updateCmdStatus(cmd?._id, "take_off").then((res: any) => {
            //   if (res) {
            //     // console.log("successssssssss")

            //     this.sweetalert.alertSuccess("La commande a été enlevé avec succès.").then(async (result: any) => {
            //       if (result.isConfirmed) {
            //         this.listCmdsClient(this.statusCmds, this.isPaied)
            //       }
            //     })
            //   }
            // });
            // this.sweetalert.alertConfirmed().then((res: any) => {
            //   // location.href = "/product/product-list"
            //   this.allMissionDrivers(this.filterDate)
            // })
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            this.sweetalert.alertCanceled().then((res: any) => {
              // location.href = "/product/product-list"
            })
          }
        })
  }

    validateAtDepot(cmd) {
    if (cmd?.etat_cmd == "take_off")
      this.sweetalert.confirmActionBtn("Vous pourrez confirmer si l'enlèvement au dépot !")
        // + this.cmd?.pickupedBy?.nameSociety + " !")
        .then((result: any) => {
          if (result.isConfirmed) {
            this.cmdsService.updateCmdStatus(cmd?._id, "transporting_depot").then((res: any) => {
              if (res) {
                // console.log("successssssssss")

                this.sweetalert.alertSuccess("L'enlèvement au dépot.").then(async (result: any) => {
                  if (result.isConfirmed) {
                    this.listCmdsClient(this.statusCmds, this.isPaied)
                  }
                })
              }
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            this.sweetalert.alertCanceled().then((res: any) => {
              // location.href = "/product/product-list"
            })
          }
        })
  }

}
