<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-add-user icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.user' | translate}}
                <!-- <div class="page-title-subheading">Ajouter un nouveau utilisateur (TRANSPORTEUR /
                    PREPARATEUR / LOUAGISTE).
                </div> -->
                <div class="page-title-subheading">{{'data.add_new_user' | translate}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <!-- <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button> -->
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="main-card mb-12 card">
        <div class="card-body">
            <form class="" action="" method="post">
                <div class="row">
                    <div class="col-12 col-md-4 form-group">
                        <legend for="lastname" class="">{{'data.last_name' | translate}} *</legend>
                        <input name="lastname" type="text" class="form-control" [(ngModel)]="user.lastname"
                            [class]="(!isSaved && user.lastname=='') ? 'input-border-danger' : ''">
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <legend for="firstname" class="">{{'data.first_name' | translate}} *</legend>
                        <input name="firstname" type="text" class="form-control" [(ngModel)]="user.firstname"
                            [class]="(!isSaved && user.firstame=='') ? 'input-border-danger' : ''">
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <div class="position-relative form-group">
                            <legend for="numtel" class="">{{'data.phone_number' | translate}} *</legend>
                            <input name="numtel" type="number" class="form-control" [(ngModel)]="user.numtel"
                                name="numtel"
                                [class]="(!isSaved && user.numtel?.toString()=='') ? 'input-border-danger' : ''">

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4 form-group">
                        <legend for="username" class="">{{'data.email' | translate}} *</legend>
                        <input name="username" type="text" class="form-control" [(ngModel)]="user.username"
                            [class]="(!isSaved && user.username=='') ? 'input-border-danger' : ''" ng-required="true"
                            ng-pattern=" ^[a-z]+[a-z0-9._-]+@[a-z]+\.[a-z.]{2,5}$">
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <legend for="password" class="">{{'data.password' | translate}} *</legend>
                        <input name="password" type="text" class="form-control" [(ngModel)]="user.password"
                            [class]="(!isSaved && (user.password=='' 
                                                || (user.password!='' && user.password != user.confirmPassword))) ? 'input-border-danger' : ''">
                    </div>

                    <!-- <div class="col-12 col-md-4 form-group">
                        <legend for="confirmPassword" class="">Confirmer mot de passe *</legend>
                        <input name="confirmPassword" type="text" class="form-control"
                            [(ngModel)]="user.confirmPassword">
                    </div> -->

                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <legend for="profil" class="">{{'data.profile' | translate}} *</legend>
                            <select class="form-control" multiple [(ngModel)]="user.profil" name="profil"
                                [class]="(!isSaved && (!user.profil)) ? 'input-border-danger' : ''">
                                <option [value]="profil" *ngFor="let profil of profiles">
                                    {{profil}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-4" *ngIf="idSociety==null">
                        <div class="position-relative form-group">
                            <legend for="society" class="">{{'data.company' | translate}} *</legend>
                            <select class="form-control" [(ngModel)]="user.society" name="society"
                                [class]="(!isSaved && (!user.society)) ? 'input-border-danger' : ''">
                                <option [value]="null">{{'data.select_company' | translate}}</option>
                                <option [value]="soc?._id" *ngFor="let soc of societies">
                                    {{soc?.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="position-relative row form-check">
                    <div class="col-sm-10 offset-sm-10">
                        <button type="submit" (click)="addUser()" class="btn btn-primary">{{'data.add_user' |
                            translate}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>