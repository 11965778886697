import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { AddBoxByProviderComponent } from '../../settings/products/windows/add-box-by-provider/add-box-by-provider.component';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';

@Component({
  selector: 'app-add-commande',
  templateUrl: './add-commande.component.html',
  styleUrls: ['./add-commande.component.css']
})

export class AddCommandeComponent implements OnInit {

  @ViewChild('autoInput') input: any;

  isClicked: Boolean = false
  isSaved: Boolean = true
  isLoading: Boolean = false;

  palletsData: any[] = []

  order: any = {}

  allFiles: any = []

  allSocieties: any[] = []
  allPalettes: any[] = []
  selectedPalette: any = null

  selectedVille: string = '';

  typesPriority: any = [
    { code: 1, label: "Opération standard", description: "Délai de livraison normal, sans urgence particulière" },
    { code: 2, label: "Opération accélérée", description: "Délai de livraison plus court que la livraison standard, généralement pour les commandes qui ont une certaine priorité" },
    { code: 3, label: "Opération express", description: "Livraison très rapide, souvent pour les commandes qui doivent être livrées dans un délai très court" },
    { code: 4, label: "Opération urgente", description: "Délai de livraison le plus court possible, généralement pour les situations d'urgence ou les commandes critiques" },
  ]
  products: any[] = [
    "xxx 123 - Piéce automobile",
    "yyy 456 - Piéce automobile",
    "zzz 789 - Piéce automobile"
  ]

  selectedProvider: any
  clientsList: any = [];

  boxform: FormGroup;
  palletForm: FormGroup;
  idSociety: string;
  constructor(private fb: FormBuilder,
    private clientService: ClientsService,
    private service: SettingsService,
    private modalService: NgbModal,
    private cmdService: CommandesService,
    private teamsServices: TeamsService) {
    this.boxform = fb.group({
      boxes: fb.array([])
    })
    this.palletForm = fb.group({
      pallets: fb.array([])
    })
    this.idSociety = this.teamsServices.getIdSociety();
  }

  async ngOnInit() {
    await this.getAllProviders()
    await this._fetchAllSocieties()

    this.allTypePalettes()
    this.addNewBoxGroup()
    this.addNewPalletGroup();
  }

  async getAllProviders() {
    this.clientsList = await this._searchProviders("")

    // await this.clientService.getClients().then((res: any) => {
    //   console.log("getAllProviders :::::::::::: ")
    //   console.log(res)
    //   if (res) {
    //     this.clientsList = res;
    //   }
    // });
  }

  get boxes(): FormArray {
    return this.boxform.get("boxes") as FormArray;
  }
  get pallets(): FormArray {
    return this.palletForm.get("pallets") as FormArray;
  }

  newBoxProduct(index) {
    this.initPalletsData()

    // console.log(box)
    const modalRef = this.modalService.open(AddBoxByProviderComponent, { size: 'lg' });
    modalRef.componentInstance.box = {
      "ref": "",
      "t_seak": "",
      "t_hght": null,
      "t_wdth": null,
      "t_dpth": null,
      "ucs": null,
    }

    modalRef.componentInstance.result.subscribe((receivedEntry) => {
      // console.log(receivedEntry)
      if (receivedEntry) {
        // console.log("receivedEntry")
        // console.log(receivedEntry)
        this.selectedProvider.boxes_products.push(receivedEntry)
        // console.log("Result from modal:", receivedEntry);
        // this._fetchBoxes()

        const add = this.boxform.get('boxes') as FormArray;

        this.mappingSelectedProductValue(receivedEntry, index, add)
      }
    });
  }

  /** BEGIN UPLOAD FILES */
  onFileSelected(event) {
    const files = event.target.files;
    // console.log(files.length)
    if (files.length > 0) {
      this.cmdService.uploadFiles(files).then((res: any) => {
        this.allFiles.push(...res?.data)
      });
    }
  }
  deleteDoc(index) {
    // console.log(index)
    this.allFiles.splice(index, 1)
  }
  /** END UPLOAD FILES */

  /** BEGIN FUNCTION SOCIETY */
  async _fetchAllSocieties() {
    await this.cmdService.allSocieties().then((res: any) => {
      // console.log("resssssssss services : ")
      // console.log(res)
      if (res) {
        this.allSocieties = res;
      }
    });
  }
  /** END FUNCTION SOCIETY */

  async allTypePalettes() {
    // console.log("alllll")
    await this.service.allPallets().then((res: any) => {
      // console.log("resssssssss services : ")
      // console.log(res)
      if (res) {
        this.allPalettes = res.data;
      }
    });
  }
  getTypePalette(i) {
    return this.pallets.controls[i].get('id_pallet')?.value
  }
  setTypePalette(event: any, i: number) {
    // console.log(event)
    var typePallet = event //.target.value
    var pallet: any = {
      id_pallet: typePallet
    }
    if (typePallet != "other") {
      this.selectedPalette = this.allPalettes.find((pal) => pal._id === event)
      // console.log(this.selectedPalette)
      pallet.name_pallet = this.selectedPalette.name
      pallet.length = this.selectedPalette.longueur
      pallet.width = this.selectedPalette.largeur
      pallet.height = this.selectedPalette.hauteur
      pallet.volume = this.cmdService.formatNumber(
        this.selectedPalette.longueur * this.selectedPalette.largeur * this.selectedPalette.hauteur, 3)
    } else {
      pallet.name_pallet = "Autre"
      pallet.length = null
      pallet.width = null
      pallet.height = null
      pallet.volume = null
    }

    this.pallets.at(i).patchValue(pallet);
  }
  calculPricePalette() {
  }

  /** BEGIN PALLET FORM GROUP */
  addNewPalletGroup() {
    this.pallets.push(this.fb.group({
      id_pallet: [],
      name_pallet: [],
      quantity: [],
      // prix_unitaire: [],
      weight: [],

      length: [],
      width: [],
      height: [],
      volume: 0,

      boxes: this.fb.array([
      ])
    }))
  }
  deletePalletGroup(index: number) {
    this.pallets.removeAt(index);

    if (this.palletForm.value.pallets.length == 0)
      this.addNewPalletGroup()

    // if (this.palletForm.value.pallets.length > 1) {
    //   this.pallets.removeAt(index);
    // } else {
    //   if (!this.onePalletNotEmpty(this.palletForm.value.pallets[index])) {
    //     this.pallets.removeAt(index);

    //     this.addNewPalletGroup()
    //   }
    // }
  }
  onePalletNotEmpty(pallet: any) {
    return (pallet.id_pallet == null || pallet.id_pallet == ""
      || pallet.name_pallet == null || pallet.name_pallet == ""
      || pallet.quantity == null || pallet.quantity < 0
      // || pallet.prix_unitaire == null || pallet.prix_unitaire < 0
      || pallet.weight == null || pallet.weight < 0
      || pallet.length == null || pallet.length < 0
      || pallet.width == null || pallet.width < 0
      || pallet.height == null || pallet.height < 0)
  }
  palletsNotEmpty(pallets) {
    if (pallets.length == 0)
      return false
    for (var i = 0; i < pallets.length; i++) {
      if (this.onePalletNotEmpty(pallets[i])) {
        return false
      }
    }
    return true
  }

  setVolumePallet(i: number) {
    var length = this.pallets.controls[i].get('length')?.value
    var width = this.pallets.controls[i].get('width')?.value
    var height = this.pallets.controls[i].get('height')?.value
    var volume
    if (length && width && height)
      volume = this.cmdService.formatNumber(length * width * height, 3)
    else
      volume = 0

    this.pallets.at(i).patchValue({
      volume: volume
    });
  }
  createBoxesPallet(): FormGroup {
    return this.fb.group({
      // id: [],
      // nom_prod: [],
      // des_prod: [],
      // ucs: [],
      // nbrPcs: [],

      quantite: [],
      weight: [],
      length: [],
      width: [],
      height: [],
      volume: 0
    });
  }
  addContent(boxIndex: number): void {
    const palletGroup = this.pallets.at(boxIndex) as FormGroup;
    const contentsArray = palletGroup.get('boxes') as FormArray;
    contentsArray.push(this.createBoxesPallet());
  }
  deleteBoxPalletGroup(boxIndex, contentIndex: number) {
    const boxGroup = this.pallets.at(boxIndex) as FormGroup;
    const contentsArray = boxGroup.get('boxes') as FormArray;
    // if (contentsArray.length > 1)
    contentsArray.removeAt(contentIndex);
  }
  setVolumeBoxPallet(boxIndex, contentIndex: number) {
    const boxGroup = this.pallets.at(boxIndex) as FormGroup;
    // const contentsArray = boxGroup.get('boxes') as FormArray;

    var contents = this.pallets.controls[boxIndex].get('boxes')?.value
    // console.log(contents)

    var length = contents[contentIndex].length
    var width = contents[contentIndex].width
    var height = contents[contentIndex].height
    var volume
    if (length && width && height)
      volume = this.cmdService.formatNumber(length * width * height, 3)
    else
      volume = 0

    const add = boxGroup.get('boxes') as FormArray;
    add.at(contentIndex).patchValue({
      volume: volume
    });
  }
  /** END PALLET FORM GROUP */

  /** BEGIN BOX FORM GROUP */
  updatePalletQuantity(event, box, i, j) {
    const palletGroup = this.pallets.at(i) as FormGroup;

    const add = palletGroup.get('boxes') as FormArray;

    add.at(j).patchValue({
      quantite: Math.ceil(event.target.value / box.value.ucs)
    });

  }
  updateQuantity(event: any, box: any, index: any) {
    this.initPalletsData()

    // console.log("event.target.value")
    // console.log(event.target.value)

    // console.log("boxxxxx")
    // console.log(box.value)

    const add = this.boxform.get('boxes') as FormArray;
    // console.log("add.at(index).value")
    add.at(index).patchValue({
      quantite: Math.ceil(event.target.value / box.value.ucs)
    });
  }

  selectBoxProductRef(event: any, index: any) {
    this.initPalletsData()

    var valueProduct = this.selectedProvider.boxes_products
      .find((element: any) => element.ref === event.target.value);

    // var valueProduct = this.client.boxes_products[event.target.value]
    // console.log(valueProduct)

    const add = this.boxform.get('boxes') as FormArray;

    this.mappingSelectedProductValue(valueProduct, index, add)
  }
  mappingSelectedProductValue(valuePost: any, index: number, contentsArray: any) {
    contentsArray.at(index).patchValue({
      id: valuePost?._id,
      nom_prod: valuePost?.ref,
      des_prod: valuePost?.t_seak,
      ucs: valuePost?.ucs,

      length: valuePost?.t_wdth,
      width: valuePost?.t_dpth,
      height: valuePost?.t_hght,
      volume: valuePost?.volume
    });
  }
  addNewBoxGroup() {
    this.initPalletsData()

    this.boxes.push(this.fb.group({
      id: [],
      nom_prod: [],
      des_prod: [],
      ucs: [],

      nbrPcs: [],
      quantite: [],
      weight: [],

      length: [],
      width: [],
      height: [],
      volume: 0
    }))
  }
  deleteBoxGroup(index: number) {

    this.boxes.removeAt(index);
    this.initPalletsData()

    if (this.boxform.value.boxes.length == 0)
      this.addNewBoxGroup()

    // if (this.boxform.value.boxes.length > 1) {
    //   this.boxes.removeAt(index);
    //   this.initPalletsData()
    // } else {
    //   if (!this.oneBoxNotEmpty(this.boxform.value.boxes[index])) {
    //     this.boxes.removeAt(index);
    //     this.initPalletsData()

    //     this.addNewBoxGroup()
    //   }
    // }
  }
  oneBoxNotEmpty(boxe: any) {
    return (boxe.nom_prod == null || boxe.nom_prod == ""
      || boxe.des_prod == null || boxe.des_prod == ""
      || boxe.quantite == null || boxe.quantite < 0
      // || boxe.prix_unitaire == null || boxe.prix_unitaire < 0
      || boxe.weight == null || boxe.weight < 0

      || boxe.length == null || boxe.length < 0
      || boxe.width == null || boxe.width < 0
      || boxe.height == null || boxe.height < 0)
  }
  boxesNotEmpty(boxes) {
    if (boxes.length == 0)
      return false

    for (var i = 0; i < boxes.length; i++) {
      if (this.oneBoxNotEmpty(boxes[i])) {
        return false
      }
    }
    return true
  }
  /** END BOX FORM GROUP */

  selectPalletBoxProductRef(event: any, i: number, index: number) {
    var valueProduct = this.selectedProvider.boxes_products
      .find((element: any) => element.ref === event.target.value);

    const palletGroup = this.pallets.at(i) as FormGroup;

    const add = palletGroup.get('boxes') as FormArray;

    this.mappingSelectedProductValue(valueProduct, index, add)
  }

  selectVille(event: any) {
    this.selectedVille = event.target.value;
  }

  // setTotalCmd() {
  //   this.totalCmd = 0
  //   this.boxform.value.boxes.forEach(prd => {
  //     if (prd.quantite != null && prd.quantite >= 0 && prd.prix_unitaire != null && prd.prix_unitaire >= 0) {
  //       this.totalCmd += prd.quantite * prd.prix_unitaire
  //     }
  //   });
  // }

  setVolumeCmd(i: number) {
    var length = this.boxes.controls[i].get('length')?.value
    var width = this.boxes.controls[i].get('width')?.value
    var height = this.boxes.controls[i].get('height')?.value
    var volume
    if (length && width && height)
      volume = this.cmdService.formatNumber(length * width * height, 3)
    else
      volume = 0

    // const add = this.boxform.get('boxes') as FormArray;
    this.boxes.at(i).patchValue({
      volume: volume
    });
  }

  async calculatePallets(pallet) {
    // console.log(pallet)
    var isValidate = this.boxesNotEmpty(this.boxform.value.boxes)
    if (isValidate) {
      await this.cmdService.calculatePallets({
        PALLET_LENGTH: pallet?.longueur,
        PALLET_WIDTH: pallet?.largeur,
        PALLET_HEIGHT: pallet?.hauteur,

        pallet_generated: pallet?._id,

        productList: this.boxform.value.boxes
      })
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)

          if (res?.response) {
            this.palletsData = res?.data
          } else {
            alert(res?.msg);
          }

        });
    } else {
      alert("erroooooor")
    }
  }

  async initCommande() {
    this.boxes.clear()
    this.addNewBoxGroup()

    this.pallets.clear()
    this.addNewPalletGroup()

    this.order = {}
    this.palletsData = []
    this.allFiles = []

    this.isSaved = true
    this.isClicked = false

    await this.getAllProviders()
    this.selectedProvider = null
    this.input.nativeElement.value = ""
  }
  checkFieldSociety() {
    if (this.idSociety != null)
      return true
    else return this.order.society != null && this.order.society != "null" && this.order.society != ""
  }
  checkFields() {
    if (this.checkFieldSociety()
      && this.order.refFacture != null && this.order.refFacture != ""
      && this.order.priority != null && this.order.priority != "null" && this.order.priority != ""
      && this.order.pickupDate != null && this.order.pickupDate != ""
      && this.order.pickupTime != null && this.order.pickupTime != ""
      && (this.boxesNotEmpty(this.boxform.value.boxes) || this.palletsNotEmpty(this.palletForm.value.pallets))) {
      return true
    } else
      return false
  }

  async addCommande() {
    var isValid = this.checkFields()
    if (!isValid) {
      this.isSaved = false
      alert("Commande est invalide")
    } else {
      this.isClicked = true

      if (this.boxesNotEmpty(this.boxform.value.boxes) && this.palletsData?.length == 0) {
        let optionsHtml = this.allPalettes.map(pal => `<option value="${pal?._id}">${pal?.name}</option>`).join('');

        Swal.fire({
          title: 'Sélectionnez le type du palette : ',
          html: `<select id="swal-select" class="swal2-input">${optionsHtml}</select>`,
          focusConfirm: false,
          preConfirm: () => {
            const select = document.getElementById('swal-select') as HTMLSelectElement;
            return select.value;
          }
        }).then((result) => {
          this.isClicked = false
          if (result.isConfirmed) {
            // console.log('Vous avez sélectionné :', result.value);

            var pal = this.allPalettes.find(item => item?._id === result.value)
            this.calculatePallets(pal)
          }
        });
      } else {

        if (this.boxesNotEmpty(this.boxform.value.boxes))
          this.order.boxes = this.boxform.value.boxes
        if (this.palletsNotEmpty(this.palletForm.value.pallets))
          this.order.pallets = this.palletForm.value.pallets

        this.order.idExp = await this.selectedProvider._id
        this.order.genPallets = this.palletsData
        this.order.uploadDocs = this.allFiles

        if (this.idSociety != null)
          this.order.society = this.idSociety

        if (this.order.pickupDate)
          //console.log("this.orderrrrrrrrr : ")
          //console.log(this.order)
          //   let userId = await this.clientService.getIdUser()
          await this.cmdService.addCmd(this.order
            // userId, nameDest.value, adressDest.value,
            // numTelDest.value, this.selectedVille, this.totalCmd, /*this.frais.frais_externe, */this.boxform.value.boxes
          ).then((res: any) => {
            // console.log("resssssssss : ")
            // console.log(res)
            if (res) {
              alert(res?.msg);
              this.initCommande()
              // this.cleanData(nameDest, adressDest, numTelDest)
              //location.href = '/sacs';
              //this.sacsList = res;
            } else {
              alert("erroooooooooooooooor")
            }
          });
      }
    }
  }

  cleanData(nameDest, adressDest, numTelDest) {
    nameDest.value = ""
    adressDest.value = ""
    numTelDest.value = ""
    this.selectedVille = ""
    // this.totalCmd = 0
    const add = this.boxform.get('boxes') as FormArray;
    while (add.length !== 0) {
      add.removeAt(0)
    }
    this.addNewBoxGroup()
  }

  initPalletsData() {
    if (this.palletsData?.length > 0)
      this.palletsData = []
  }

  /** BEGIN search provider */
  async onChange() {
    // console.log(this.input.nativeElement.value)
    // if (this.input.nativeElement.value !== "") {
    this.clientsList = await this._searchProviders(this.input.nativeElement.value)

    //   console.log("clientsList :::::::::::: ")
    //   console.log(this.clientsList)
    // }
  }
  onSelectionChange(event: any) {
    this.selectedProvider = event.option.value;
    // console.log(this.selectedProvider)

    this.input.nativeElement.value = this.selectedProvider?.providerCode
      + " - " + this.selectedProvider?.providerName

    // this.box.provider = this.provider?._id

    this.clientsList = []
  }
  _searchProviders(keyword: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.clientService.filterClient(keyword).then((res: any) => {
        console.log(res)
        if (res) {
          resolve(res)
        }
      }).catch((e: any) => {
        resolve([])
      });
    });
    return promise
  }
  /** END search provider */

}
