<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <!-- <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <ngx-avatar class="rounded-circle" name="{{client.prenom_client}}
                                                {{client.nom_client}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{client.prenom_client}}
                                                {{client.nom_client}}</div>
                                            <div class="widget-subheading opacity-7">{{client.company_name}}</div>
                                        </div>
                                    </div>
            </div> -->
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.pickup' | translate}}
                <div class="page-title-subheading">{{'data.customer_pickup_list' | translate}}.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
                            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                                class="btn-shadow mr-3 btn btn-dark">
                                <i class="fa fa-star"></i>
                            </button>
                            <div class="d-inline-block dropdown">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    class="btn-shadow dropdown-toggle btn btn-info">
                                    <span class="btn-icon-wrapper pr-2 opacity-7">
                                        <i class="fa fa-business-time fa-w-20"></i>
                                    </span>
                                    Buttons
                                </button>
                                <div tabindex="-1" role="menu" aria-hidden="true"
                                    class="dropdown-menu dropdown-menu-right">
                                    <ul class="nav flex-column">
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-inbox"></i>
                                                <span>
                                                    Inbox
                                                </span>
                                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-book"></i>
                                                <span>
                                                    Book
                                                </span>
                                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-picture"></i>
                                                <span>
                                                    Picture
                                                </span>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                                <i class="nav-link-icon lnr-file-empty"></i>
                                                <span>
                                                    File Disabled
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">{{'data.filtred' | translate}}
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                                        <div class="badge badge-warning">{{statusCmds}}</div>
                                    </div> -->
                </div>
            </div>
            <div class="tab-responsive">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="filterCmds();">
                            <div class="form-row">
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="numero" class="">{{'data.provider' | translate}}
                                        </legend>
                                        <input name="nuprovidermero" id="provider" type="text" class="form-control"
                                            [(ngModel)]="filter.provider">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ref" class="">{{'data.reference' | translate}}</legend>
                                        <input name="ref" id="ref" type="text" class="form-control"
                                            [(ngModel)]="filter.ref">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="stat" class="">{{'data.status' | translate}}</legend>
                                        <select class="form-control selectric" (change)="selectChangeStatus($event)">
                                            <option [value]="stat" *ngFor="let stat of status">
                                                {{stat}}</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Ville</legend>
                                        <select class="form-control selectric" (change)="selectChangeVille($event)">
                                            <option [value]="ville" *ngFor="let ville of villes">
                                                {{ville}}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datein" class="">{{'data.start_date' | translate}}</legend>
                                        <input id="datein" type="date" class="form-control" name="datein" tabindex="1"
                                            [(ngModel)]="filter.startDate">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datefin" class="">{{'data.end_date' | translate}}</legend>
                                        <input id="datefin" type="date" class="form-control" name="datefin" tabindex="1"
                                            [(ngModel)]="filter.endDate">
                                    </div>
                                </div>
                                <button type="submit" (click)="filterCmds()"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search"
                                    tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading; else elseAllCmds">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
            </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseAllCmds>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">{{'data.pickups' | translate}}
                    <!-- <div class="btn-actions-pane-right">
                        <div role="group" class="btn-group-sm btn-group">
                            <button class="active btn btn-focus">Last Week</button>
                            <button class="btn btn-focus">All Month</button>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="card-body"> -->
                <!-- <div class="tab-content"> -->
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="cmdList?.length === 0">
                        <h2 class="text-center text-danger">{{'data.no_pickup_created' | translate}}</h2>
                    </div>
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                        *ngIf="cmdList?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th>{{'data.provider' | translate}}</th>
                                <th>{{'data.reference' | translate}}</th>
                                <th>{{'data.date_pickup' | translate}}</th>
                                <!-- <th class="text-center">Nom Client<br>&<br>Téléphone</th>
                                    <th class="text-center">Ville</th> -->
                                <th class="text-center">{{'data.products' | translate}}<br>
                                    {{'data.quantity_product_name' | translate}}</th>
                                <th>{{'data.comment' | translate}}</th>
                                <th class="text-center">{{'data.volume' | translate}} <br> {{'data.boxes' |
                                    translate}}<br>(m³) </th>
                                <th class="text-center">{{'data.volume' | translate}} <br> {{'data.pallet' |
                                    translate}}<br>(m³)</th>
                                <th class="text-center">{{'data.total_weight' | translate}} <br> (Kg) </th>
                                <th class="text-center">{{'data.status' | translate}}</th>
                                <th class="text-center">Total (€) </th>
                                <th class="text-center">{{'data.old' | translate}} <br> Total (€)</th>
                                <th class="text-center" data-width="100">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let cmd of cmdList, let index = index">
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <ngx-avatar class="rounded-circle" name="{{cmd?.client_cmd?.prenom_client}}
                                                        {{cmd?.client_cmd?.nom_client}}">
                                                    </ngx-avatar>
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">
                                                    {{cmd?.client_cmd?.providerName}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{cmd?.client_cmd?.shippingAdress?.label_target}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td style="width: 200px; word-wrap: break-word; word-break: break-all;">
                                    {{cmd.ref_facture_cmd}}</td>
                                <td class="text-left">
                                    {{cmd.pickupDate | date:'d MMM y'}}<br>
                                    <b>{{cmd.pickupDate | date:'HH:mm'}}</b>
                                </td>
                                <td>
                                    <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                        *ngIf="cmd?.totalBoxes > 0">
                                        <li class="list-group-item">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3 widget-pallet">
                                                        <img width="100%"
                                                            src="https://assets.marketbey.tn/WTM/products/box.png"
                                                            alt="">
                                                    </div>
                                                    <div class="widget-content-right">
                                                        <div class="font-size-lg text-muted">
                                                            <span>
                                                                {{ cmd?.totalBoxes }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                        *ngIf="cmd?.totalPallets > 0">
                                        <li class="list-group-item">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3 widget-pallet">
                                                        <img width="100%"
                                                            src="https://assets.marketbey.tn/WTM/products/pallet.png"
                                                            alt="">
                                                    </div>
                                                    <div class="widget-content-right">
                                                        <div class="font-size-lg text-muted">
                                                            <span>
                                                                {{ cmd?.totalPallets }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <!-- <div *ngIf="cmd?.genPallets?.length > 0; else elseAllBoxes">
                                        <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                            <li class="list-group-item"
                                                *ngFor="let pallet of cmd?.genPallets, let index = index">
                                                <div class="widget-content p-0">
                                                    <div class="widget-content-wrapper">
                                                        <div class="widget-content-left mr-3 widget-pallet">
                                                            <img width="100%"
                                                                src="https://assets.marketbey.tn/WTM/products/pallet.png"
                                                                alt="">
                                                        </div>
                                                        <div class="widget-content-left">
                                                            <div class="widget-heading">
                                                                ({{pallet?.palletNumber}}) - {{pallet?.countBoxes}}
                                                                Boxe(s)
                                                            </div>
                                                            <div class="widget-subheading">
                                                                {{pallet?.layers?.length}} Couche
                                                            </div>
                                                        </div>
                                                        <div class="widget-content-right">
                                                            <div class="font-size-lg text-muted">
                                                                <span>{{pallet?.dimensions?.width *
                                                                    pallet?.dimensions?.depth *
                                                                    pallet?.dimensions?.height | number:
                                                                    '0.1'}}m³</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <ng-template #elseAllBoxes>
                                        <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                            *ngIf="cmd?.boxes?.length > 0">
                                            <li class="list-group-item"
                                                *ngFor="let box of cmd?.boxes, let index = index">
                                                <div class="widget-content p-0">
                                                    <div class="widget-content-wrapper">
                                                        <div class="widget-content-left mr-3 widget-box">
                                                            <img width="100%"
                                                                src="https://assets.marketbey.tn/WTM/products/box.png"
                                                                alt="">
                                                        </div>
                                                        <div class="widget-content-left">
                                                            <div class="widget-heading">
                                                                {{box?.quantite}} - {{box?.nom_prod}}
                                                            </div>
                                                            <div class="widget-subheading">
                                                                {{box?.des_prod}}
                                                            </div>
                                                        </div>
                                                        <div class="widget-content-right">
                                                            <div class="font-size-lg text-muted">
                                                                <span>{{box?.quantite * box?.volume | number:
                                                                    '0.1'}}m³</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </ng-template>

                                    <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                        *ngIf="cmd?.pallets?.length > 0">
                                        <li class="list-group-item"
                                            *ngFor="let pallet of cmd?.pallets, let index = index">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3 widget-pallet">
                                                        <img width="100%"
                                                            src="https://assets.marketbey.tn/WTM/products/pallet.png"
                                                            alt="">
                                                    </div>
                                                    <div class="widget-content-left">
                                                        <div class="widget-heading">
                                                            {{pallet?.quantity}} - {{pallet?.name_pallet}}
                                                        </div>
                                                    </div>
                                                    <div class="widget-content-right">
                                                        <div class="font-size-lg text-muted">
                                                            <span>{{pallet?.quantity * pallet?.volume | number:
                                                                '0.1'}}m³</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul> -->
                                </td>
                                <!-- <td class="text-center">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">
                                                        {{cmd.user_destination.nom_destination}}</div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd.user_destination.numtel_destination}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        {{cmd.user_destination.adresse_destination}}<br>
                                        <strong>{{cmd.destination_cmd}}</strong>
                                    </td> -->
                                <!-- <td>
                                        <div *ngFor="let prd of cmd.products, let index = index">
                                            {{prd.quantite}} - {{prd.nom_prod}}
                                        </div>
                                    </td> -->
                                <td class="text-center">
                                    <span class="badge badge-pill badge-danger"
                                        (click)="listComments(cmd)">{{cmd.comments?.length}}</span>
                                </td>
                                <td class="text-center">
                                    {{ cmd?.totalVolume | number:'0.1' }}
                                </td>
                                <td class="text-center">
                                    {{ cmd?.totalPalletVolume | number:'0.1' }}
                                </td>
                                <td class="text-center">
                                    {{ cmd?.totalPoids | number:'0.1' }}
                                </td>
                                <td class="text-center">
                                    <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                        {{statusCmds[cmd.etat_cmd].label}}</div>
                                </td>

                                <td class="text-center">{{cmd.total_ca_cmd |
                                    number:'0.3'}}</td>
                                <td class="text-center">{{cmd.realTotalCaCmd |
                                    number:'0.3'}}</td>
                                <td class="text-center">
                                    <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                        <button class="btn-wide btn btn-info">Actions</button>
                                        <button type="button" ngbDropdownToggle
                                            class="dropdown-toggle-split btn btn-info"><span
                                                class="sr-only">Actions</span></button>
                                        <div ngbDropdownMenu>
                                            <button type="button" *ngIf="cmd?.etat_cmd == 'invalid'"
                                                (click)="validateCmd(cmd._id)" tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-check text-warning"
                                                    style="margin-right: 10px;"></i>{{'data.validate_pickup' |
                                                translate}}
                                            </button>
                                            <button type="button" *ngIf="cmd?.etat_cmd == 'validated'"
                                                (click)="sendMail(cmd)" tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-mail-open-file text-primary"
                                                    style="margin-right: 10px;"></i>{{'data.send_email' | translate}}
                                            </button>
                                            <button type="button" *ngIf="cmd?.etat_cmd == 'validated'"
                                                (click)="validateTakeOff(cmd)" tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-check text-danger"
                                                    style="margin-right: 10px;"></i>{{'data.validate_pickup' |
                                                translate}}
                                            </button>
                                            <button type="button" *ngIf="cmd?.etat_cmd == 'take_off'"
                                                (click)="validateAtDepot(cmd)" tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-check text-danger"
                                                    style="margin-right: 10px;"></i>{{'data.at_depot' |
                                                translate}}
                                            </button>
                                            <button type="button" (click)="goToNewTab(cmd._id, '/detail-cmd')"
                                                tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-news-paper"
                                                    style="margin-right: 10px;"></i>{{'data.details' | translate}}
                                            </button>
                                            <button type="button" tabindex="0"
                                                (click)="goToNewTab(cmd._id, '/facture-cmd')" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-print"
                                                    style="margin-right: 10px;"></i>{{'data.invoice' | translate}}
                                            </button>
                                            <button *ngIf="cmd?.etat_cmd=='validated' || cmd?.etat_cmd=='invalid'"
                                                type="button" [routerLink]="['/edit-commande']"
                                                [queryParams]="{ cmd: cmd?._id, client: cmd?.client_cmd?._id }"
                                                tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-pen text-primary"
                                                    style="margin-right: 10px;"></i>{{'data.edit_pickup' | translate}}
                                            </button>
                                            <button
                                                *ngIf="cmd?.etat_cmd=='validated' || cmd?.etat_cmd=='invalid' || cmd?.etat_cmd=='canceled'"
                                                type="button" tabindex="0" class="dropdown-item"
                                                (click)="deleteCmd(cmd?._id)">
                                                <i class="metismenu-icon pe-7s-trash text-danger"
                                                    style="margin-right: 10px;"> {{'data.delete_pickup' | translate}}
                                                </i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-block text-center card-footer" *ngIf="cmdList?.length > 0 && pages?.length > 1">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                                <a class="page-link" tabindex="-1" (click)="previousPage()">
                                    <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                                    {{'data.previous' | translate}}</a>
                            </li>

                            <div *ngFor="let p of pagesDisplaied">
                                <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                    <a *ngIf="currentPage!==p" class="page-link" (click)="selectPage(p)">{{p+1}}</a>

                                    <span *ngIf="currentPage===p" class="page-link">
                                        {{p+1}}
                                        <span class="sr-only">(current)</span>
                                    </span>
                                </li>
                            </div>

                            <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                                <a class="page-link" tabindex="-1" (click)="nextPage()">{{'data.next' | translate}} <i
                                        class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <!-- </div> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</ng-template>