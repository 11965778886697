import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TravelersService } from 'src/app/services/travelers/travelers.service';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})
export class ReservationsComponent implements OnInit {

  tripsList: Observable<Array<any>>;

  constructor(private travelersService: TravelersService) { }

  ngOnInit(): void {
    //this.listTrips()
  }

  // async listTrips() {
  //   await this.travelersService.getTrips().then((res: any) => {
  //     // console.log("listTrips : ")
  //     // console.log(res)
  //     if (res) {
  //       this.tripsList = res;
  //     }
  //   });
  // }

}
