import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TravelersService {

  constructor(private http: HttpClient) { }

  // async getTravelers() {
  //   return await new Promise((slv) => {
  //     this.http.post(environment.apiAllTravelers, {}).subscribe(
  //       (data: any) => {
  //         if (data && data.response && data.data) {
  //           slv(data.data);
  //         } else slv(false);
  //       },
  //       (error) => {
  //         slv(false);
  //       }
  //     );
  //   });
  // }

  // async getTrips() {
  //   return await new Promise((slv) => {
  //     this.http.post(environment.apiAllTrips, {}).subscribe(
  //       (data: any) => {
  //         if (data && data.response && data.data) {
  //           slv(data.data);
  //         } else slv(false);
  //       },
  //       (error) => {
  //         slv(false);
  //       }
  //     );
  //   });
  // }

  // async getOneTrip(idTrip) {
  //   return await new Promise((slv) => {
  //     this.http.post(environment.apiOneTrip, {"idTrip": idTrip}).subscribe(
  //       (data: any) => {
  //         if (data && data.response && data.data) {
  //           slv(data.data);
  //         } else slv(false);
  //       },
  //       (error) => {
  //         slv(false);
  //       }
  //     );
  //   });
  // }
}
