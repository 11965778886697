<div class="modal-header">
    <h4 class="modal-title">{{'data.add_new_box' | translate}}</h4>
    <button type="button" aria-label="Close" (click)="closeModal()">
        <i class="fa fa-close"></i>
    </button>
</div>
<div class="modal-body">

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-12 col-md-5">
                                    <legend for="ref" class="">{{'data.reference' | translate}} *</legend>
                                    <input name="ref" id="ref" type="text" class="form-control" [(ngModel)]="box.ref"
                                        [class]="(!isSaved && box.ref=='') ? 'input-border-danger' : ''">
                                </div>

                                <div class="col-12 col-md-7">
                                    <legend for="t_seak" class="">{{'data.description' | translate}} *</legend>
                                    <input name="t_seak" id="t_seak" type="text" class="form-control"
                                        [(ngModel)]="box.t_seak"
                                        [class]="(!isSaved && box.t_seak=='') ? 'input-border-danger' : ''">
                                </div>

                                <!-- <div class="col-12 col-md-5">
                                    <legend for="name" class="">Fournisseur *</legend>

                                    <input type="text" name="provider" id="provider" class="form-control" #autoInput
                                        matInput [matAutocomplete]="auto" (input)="onChange()"
                                        [class]="(!isSaved && box.provider==null) ? 'input-border-danger' : ''">

                                    <mat-autocomplete #auto="matAutocomplete"
                                        (optionSelected)="onSelectionChange($event)">
                                        <mat-option *ngFor="let provider of providers" [value]="provider">
                                            {{ provider?.providerCode + ' - ' + provider?.providerName }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div> -->
                            </div>

                            <div class="row form-group">
                                <div class="col-12 col-md-3">
                                    <legend for="t_wdth" class="">{{'data.length' | translate}} (m) *</legend>
                                    <input name="t_wdth" id="t_wdth" type="number" class="form-control"
                                        [(ngModel)]="box.t_wdth"
                                        [class]="(!isSaved && box?.t_wdth==null) ? 'input-border-danger' : ''">
                                </div>

                                <div class="col-12 col-md-3">
                                    <legend for="t_dpth" class="">{{'data.width' | translate}} (m) *</legend>
                                    <input name="t_dpth" id="t_dpth" type="number" class="form-control"
                                        [(ngModel)]="box.t_dpth"
                                        [class]="(!isSaved && box?.t_dpth==null) ? 'input-border-danger' : ''">
                                </div>

                                <div class="col-12 col-md-3">
                                    <legend for="t_hght" class="">{{'data.height' | translate}} (m) *</legend>
                                    <input name="t_hght" id="t_hght" type="number" class="form-control"
                                        [(ngModel)]="box.t_hght"
                                        [class]="(!isSaved && box?.t_hght==null) ? 'input-border-danger' : ''">
                                </div>

                                <div class="col-12 col-md-3">
                                    <legend for="ucs" class="">{{'data.number_of_units' | translate}} *</legend>
                                    <input name="ucs" id="ucs" type="number" class="form-control" [(ngModel)]="box.ucs"
                                        [class]="(!isSaved && box?.ucs==null) ? 'input-border-danger' : ''">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="saveBox()">{{'data.save' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="closeModal()">{{'data.close' | translate}}</button>
</div>