import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-bon-achat',
  templateUrl: './bon-achat.component.html',
  styleUrls: ['./bon-achat.component.css']
})
export class BonAchatComponent implements OnInit {

  ville: string = ""
  listCmds: any = []
  nowDate = new Date();

  constructor(private route: ActivatedRoute,
    private cmdsService: CommandesService,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      this.ville = params.ville;
      //this.listCmdsByVille()
    });
  }

  // async listCmdsByVille() {
  //   let lstSacs = []
  //   await this.cmdsService.allCmdsByVille(this.ville).then((res: any) => {
  //     if (res) {
  //       lstSacs = res;
  //     }
  //   });
  //   lstSacs.forEach(sac => {
  //     if (sac != null) {
  //       sac.forEach(cmd => {

  //         this.listCmds.push(cmd)

  //       });
  //     }
  //   });
  // }

  generatePDF() {
    var divContents = document.getElementById("commandePrete").innerHTML;
    var a = window.open('', '', 'height=860, width=1600');
    a.document.write('<html><head> ');
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/style.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/components.css" />'
    );
    a.document.write(
      '</head><body style="background:#FFFFFF" ><div  class="container invoice">'
    );

    a.document.write(divContents);
    a.document.write('</div></body></html>');
    a.document.close();
    a.print();
  }


}
